import React, { Component } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { Switch, Route } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import PropTypes from 'prop-types';
import TitleRow from '../components/titleRow';
import BarSearchMoreAdd from '../components/barSearchMoreAdd';
import EmptyFieldMessageElement from '../components/emptyFieldMessageElement';
import CatalogBox from '../components/catalogBox';
import Loading from '../components/loading';
import FormDocumentAction from './formDocumentAction';

@withTranslation()
@connect(
  state => ({
    projectData: state.IBIS.cache.get('currentProject')
  })
)
export default class DocumentBoard extends Component {
  state = {
    isShowAddMenu: false,
    isShowLoading: false
  };

  renderFiles = (files) => {
    return files.map((item, idx) => {
      return (
        <CatalogBox
          title={item.get("name")}
          imgPath={item.get("source")}
          extraCssClass="mb-10"
          linkPathName={`${this.props.match.url}/edit`}
          linkState={{ editItem: item, itemIdx: idx }}
          key={uuidv4()}
          nameAlign="text-center"
          fileInfo={item.get("fileInfo").toJS()}
        />
      );
    });
  };

  renderPhotosContainer = (catalog) => {
    if (catalog === undefined || catalog.get("files").size === 0) {
      return (<EmptyFieldMessageElement msg={this.props.t('project.db_empty_documents')}/>);
    }
    else {
      return (
        <Row className={(catalog.get("files").size > 0) ? "more-photos" : ""}>
          {this.renderFiles(catalog.get("files"))}
        </Row>
      );
    }
  };

  handleChangeAddPhoto= (event) => {
    this.setState({ isShowLoading: true });
    event.preventDefault();
    let file = event.target.files[0];
    let reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onloadend = () => {
      this.setState({ isShowLoading: false });
      this.props.history.push({
        pathname: `${this.props.match.url}/add`,
        state: { fileSource: reader.result,
          fileInfo: {
            name: file.name,
            size: file.size,
            type: file.type,
            lastModified: file.lastModified,
            lastModifiedDate: file.lastModifiedDate.toLocaleString()
          } } });
    };
    this.setState({ isShowAddMenu: false });
  };

  renderContent = () => {
    const { t } = this.props;
    const catalogData = this.props.projectData.getIn([ 'database', "documents", this.props.match.params.catalog ]);
    return (
      <React.Fragment>
        <Row className="documents">
          <Col>
            <TitleRow title={catalogData.get("catalogName")} extraCssClass="main-title"/>
            <BarSearchMoreAdd centerIcon="camera" onClickAdd={() => this.setState({ isShowAddMenu: true })}
              onCenterBtnChange={this.handleChangeAddPhoto}/>
            {this.renderPhotosContainer(catalogData)}
          </Col>
        </Row>
        {this.state.isShowAddMenu &&
          <React.Fragment>
            <div className="dashboard-cover"/>
            <Row className="menu-add-file px-2">
              <Col className="w-100">
                <Button className="font-medium cursor" color="white">
                  <input id="refBtnTakehoto" type="file" accept="image/*" capture onChange={this.handleChangeAddPhoto}
                    className="hideElement"/>
                  <label htmlFor="refBtnTakehoto" className="w-100 cursor">{t('project.take_photo')}</label>
                </Button>
                <Button className="font-medium cursor" color="white">
                  <input id="refBtnAddPhoto" type="file"
                    accept={"image/*,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocu"+
                    "ment.wordprocessingml.document,.csv,application/vnd.openxmlformats-officedocument.spreadshee"+
                    "tml.sheet,application/vnd.ms-excel,.pdf,.txt,text/html"}
                    onChange={this.handleChangeAddPhoto} className="hideElement"/>
                  <label htmlFor="refBtnAddPhoto" className="w-100 cursor">{t('project.add_from_files')}</label>
                </Button>
                <Button className="font-medium disable" color="white">{t('project.paste_link')}</Button>
                <Button className="font-medium w-100 cursor" color="white"
                  onClick={() => this.setState({ isShowAddMenu: false })}>{t('form.cancel')}</Button>
              </Col>
            </Row>
          </React.Fragment>
        }
        {this.state.isShowLoading && <Loading/>}
      </React.Fragment>
    );
  };

  render() {
    return (
      <Switch>
        <Route exact path={this.props.match.path}>
          {this.renderContent()}
        </Route>
        <Route path={`${this.props.match.path}/add`} render={props => (
          <FormDocumentAction {...props} group="documents" action="add"/>
        )}/>
        <Route path={`${this.props.match.path}/edit`} render={props => (
          <FormDocumentAction {...props} group="documents" action="edit"/>
        )}/>
      </Switch>
    );
  }
}

DocumentBoard.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  projectData: PropTypes.object,
  t: PropTypes.func
};
