import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import importedIconAr from '../../assets/img/icons/icon_AR.svg';
import importedIconDocuments from '../../assets/img/icons/icon_documents.svg';
import importedIconDocuments_red from '../../assets/img/icons/icon_documents2.svg';
import importedIconInspiration from '../../assets/img/icons/icon_inspiration.svg';
import importedIconInspiration_red from '../../assets/img/icons/icon_inspiration2.svg';
import importedIconProducts from '../../assets/img/icons/icon_products.svg';
import importedIconProducts_red from '../../assets/img/icons/icon_products2.svg';
import importedIconWallet from '../../assets/img/icons/icon_wallet.svg';
import importedIconWallet_red from '../../assets/img/icons/icon_wallet2.svg';
import importedIconCalendar from '../../assets/img/icons/icon_calendar.svg';
import importedIconCalendar_red from '../../assets/img/icons/icon_calendar2.svg';

@withTranslation()
export default class Footer extends Component {
    createIconParent = (iconName, iconText, active, inactive) => {
      let srcToSet = inactive;
      if (this.props.location.pathname.includes(iconName)) {
        srcToSet = active;
      }
      return (
        <Col>
          <Link to={'/project/'+this.props.match.params.projectId+'/'+iconName} className="d-flex align-items-center flex-column">
            <img src={srcToSet} alt={iconText}/>
            <p className={srcToSet === active ? "font-clicked text-center": "text-center"}>{iconText}</p>
          </Link>
        </Col>
      );
    };

    render() {
      //TODO: trzeba ukryc footer dla niezarejestrownych/niezalogowanych
      if (this.props.location.state !== undefined
            && this.props.location.state.isFooterHide !== undefined
            && this.props.location.state.isFooterHide) {
        return null;
      }

      //TODO: + trzeba ukryc footer dla niezarejestrownych/niezalogowanych
      if (this.props.location.pathname === "/profile" || (this.props.location.pathname).startsWith("/createnew")
      || this.props.location.pathname.includes("add") || this.props.location.pathname.includes("edit")) {
        return null;
      }

      return (
        <Row className="footer-container">
          <Col>
            <hr className="hr-shadow"/>
            <Row className="footer">
              {this.createIconParent("inspiration", "Inpiracje", importedIconInspiration_red, importedIconInspiration)}
              {this.createIconParent("products", "Produkty", importedIconProducts_red, importedIconProducts)}
              {this.createIconParent("documents", "Dokumenty", importedIconDocuments_red, importedIconDocuments)}
              {this.createIconParent("budget", "Budżet", importedIconWallet_red, importedIconWallet)}
              {this.createIconParent("calendar", "Kalendarz", importedIconCalendar_red, importedIconCalendar)}
              <Col className="d-flex align-items-center flex-column">
                <img src={importedIconAr} alt="ar"/>
                <p>AR</p>
              </Col>
            </Row>
          </Col>
        </Row>
      );
    }
}

Footer.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object
};