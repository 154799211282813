import { createStore, applyMiddleware, compose } from 'redux';
import { combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import createHistory from 'history/createBrowserHistory';
import { reducer as toastrReducer } from 'react-redux-toastr';

import { __env } from '../../envloader';
import { loginReducer } from '../../oidc-middleware/reducers/login-reducer';
import { getOidcMiddleware } from '../../oidc-middleware/middleware';
import { createProjectDefaultDataReducer } from '../../voxbox-asystent-ui/reducers/createProjectDefaultDataReducer';

import { IBISReducers } from 'ibis-ui/ibis-client-middleware/reducers/index';
import { getIbisMiddleware } from 'ibis-ui/ibis-client-middleware/middleware';

const history = createHistory();

const reducers = {
  router: connectRouter(history),
  login: loginReducer,
  newProjectDataReducer: createProjectDefaultDataReducer,
  toastr: toastrReducer,
  IBIS: IBISReducers
};

const rootReducer = combineReducers(reducers);

// as found on: https://github.com/zalmoxisus/redux-devtools-extension#12-advanced-store-setup
const composeEnhancers =
	typeof window === 'object' &&
		window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
	  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
	    // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
	  }) : compose;

export default (initialState, axiosAuthInstance) => {
  let middlewares = [ thunk, getIbisMiddleware(axiosAuthInstance), getOidcMiddleware(axiosAuthInstance) ];

  if (__env.NODE_ENV === 'development') {
    // related resources for that strange .default below
    // https://github.com/evgenyrodionov/redux-logger/issues/98
    // https://github.com/59naga/babel-plugin-add-module-exports
    // https://github.com/babel/babel/issues/2212

    if (__env.USE_REDUX_LOGGER) {
      const createLogger = require('redux-logger');

      const logger = createLogger({
        stateTransformer: (state) => (JSON.parse(JSON.stringify(state)))
      });
      middlewares = [ ...middlewares, logger ];
    }
  }

  const rootReducerWithRouter = connectRouter(history)(rootReducer);
  const middleware = applyMiddleware(...middlewares, routerMiddleware(history));
  const store = createStore(rootReducerWithRouter, initialState, composeEnhancers(middleware));

  return {
    store,
    history,
  };
};