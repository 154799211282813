import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import importedIconAdd from '../../assets/img/icons/icon_add.svg';
import importedIconSave_white from '../../assets/img/icons/icon_pin_white.svg';
import importedIconSave from '../../assets/img/icons/icon_pin.svg';

export default function CustomButton({ action, color, extraCssClass=null, onClickFun, text, icon=null }) {
  let cssClassName = "d-flex align-items-center justify-content-center font-medium cursor "+extraCssClass;
  cssClassName += " btn-"+color;
  cssClassName += ( icon ? " btn-with-icon": " btn-with-text box-share");

  if (icon) {
    return (
      <Button className={cssClassName} onClick={onClickFun}>
        <img src={getIconByActionName(action, color)} alt="action-text"/>
        <p>{text}</p>
      </Button>
    );
  }
  else {
    return (
      <Button className={cssClassName} onClick={onClickFun}>{text}</Button>
    );
  }
}

function getIconByActionName(action, color) {
  switch (action) {
  case 'add':
    return importedIconAdd;
  case 'save':
    return color === "red" ? importedIconSave_white: importedIconSave;
  default:
    return null;
  }
}

CustomButton.propTypes = {
  extraCssClass: PropTypes.string,
  text: PropTypes.string,
  color: PropTypes.string,
  onClickFun: PropTypes.func
};