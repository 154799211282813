import { Map, List } from 'immutable';

import { SET_ATTRIBUTE } from '../actions/index';

const defaultState = Map({
  'status_code': List([ '200', '201', '204', '400', '401', '403', '404', '409', '500', '503', '2xx', '3xx', '4xx', '5xx' ])
});

export const attributeReducer = (state = defaultState, action) => {
  switch (action.type) {

  case SET_ATTRIBUTE:
    return state.setIn([ action.payload.attribute.name ], action.payload.attribute.values.map(x => x.name));

  default:
    return state;
  }
};
