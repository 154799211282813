import React from 'react';
import { Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import ImagePreview from './imagePreview';

export default function catalogBox({ imgSource, imgPath, extraCssClass, index, linkPathName, linkState, isImgOfCatalog,
  nameAlign, title, type, fileInfo }) {
  let cssClassName = "d-flex flex-column catalog-box-container mr-3 ";
  if (extraCssClass !== undefined) {
    cssClassName += " "+ extraCssClass;
  }

  return (
    <Link to={{
      pathname: linkPathName,
      state: linkState }}>
      <Col className={cssClassName}>
        {createImgFromPath(imgPath, isImgOfCatalog, fileInfo)}
        {renderCatalogTitle(nameAlign, title, type)}
      </Col>
    </Link>
  );
}

function renderCatalogTitle(nameAlign, title, type) {
  if (type === "product") {
    return <p className="catalog-name d-flex justify-content-between">
      <span>{title.name}</span>
      <span className="text-right">{title.price}</span>
    </p>;
  }
  else {
    return <p className={"catalog-name " + (nameAlign ? nameAlign : "")}>{title}</p>;
  }
}

function createImgFromPath(imgPath, isImgOfCatalog, fileInfo) {
  if (imgPath) {
    if (isImgOfCatalog === undefined) { //photo
      return (<ImagePreview filePath={imgPath} className="img-miniature" alt="miniature" fileInfo={fileInfo}/>);
    }
    else { //catalog first img
      return (
        <p className="box-simulate-catalog">
          <ImagePreview filePath={imgPath} className="img-miniature" alt="miniature" fileInfo={fileInfo}/>
        </p>
      );
    }
  }
  return (<p className="box-simulate-catalog"></p>);
}

catalogBox.propTypes = {
  imgSource: PropTypes.string,
  imgPath: PropTypes.string,
  extraCssClass: PropTypes.string,
  index: PropTypes.number,
  linkPathName: PropTypes.string,
  linkState: PropTypes.object,
  isImgOfCatalog: PropTypes.bool,
  nameAlign: PropTypes.string,
  title: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]),
  type: PropTypes.string,
  fileInfo: PropTypes.object
};