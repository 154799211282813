import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container, Col, Form, FormGroup, Label, Input, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { fromJS, Map } from 'immutable';

import { addRoomsToNewProject, addRenovationWorksToNewProject } from '../actions/projectActions';
import { loadPlaceStateList, loadListOfRooms, loadListOfRenovationWorks } from '../actions/createProjectDefaultDataActions';
import RadioVox from '../components/radioBtnVox';
import TitleRow from '../components/titleRow';
import CustomButton from '../components/customButton';
import CheckBoxBtnVox from '../components/checkboxBtnVox';
import importedIconClose from '../../assets/img/icons/icon_close.svg';
import importedIconBack from '../../assets/img/icons/icon_back.svg';
import importedIconLogo from '../../assets/img/icons/logo.svg';
import * as notify from '../../main/utils/notify';
import IBIS_actions from 'ibis-ui/ibis-client-middleware/interface';

@withTranslation()
@connect(
  state => ({
    newProjectData: state.newProjectDataReducer,
  }),
  dispatch => {
    return bindActionCreators({  addRoomsToNewProject, addRenovationWorksToNewProject,
      loadPlaceStateList, loadListOfRooms, loadListOfRenovationWorks,
      loadFileContent: IBIS_actions.loadFileContent,
      registerMapping: IBIS_actions.registerMapping,
      createSpace: IBIS_actions.createSpace,
      createPackage: IBIS_actions.createPackage,
      createFile: IBIS_actions.createFile,
      patchJSON: IBIS_actions.patchJSON, }, dispatch);
  }
)
export default class CreateNewProject extends Component {
  state = {
    isStepComplete: false,
    isReturnLastValues: false,
    formOption: "namePlace",
    backFormOption: null,
    projectId: uuidv4().split('-').join(''),
    projectName: '',
    selectedOption: "radio_house_raw_open",
    currFormData: null,
    countOfChecked: null,
    selectedData: null,
    newDataItem: '',
    newData: { "rooms": [], "renovationWorks": [] }
  };

  componentDidMount() {
    this.props.loadPlaceStateList();
    this.props.loadListOfRooms();
    this.props.loadListOfRenovationWorks();
    //on mobile browser on some devices btn next was hide input of project name
    window.scrollTo(0, 55);
  }

  static getDerivedStateFromProps(props, state) {
    if (!state.currFormData && props.newProjectData) {
      return { currFormData: { "rooms":  props.newProjectData.get("rooms"),
        "renovationWorks": props.newProjectData.get("renovationWorks") },
      countOfChecked: { "rooms": 0, "renovationWorks": 0 } };
    }
    return null;
  }

  updateProjectName = (e) => {
    let value = e.target.value;
    this.setState({
      projectName: value,
      isStepComplete: (value !== "")
    });
  };

  updateNewDataItem = (e) => {
    let value = e.target.value;
    this.setState({
      newDataItem: value,
      isStepComplete: (value !== "")
    });
  };

    handleOptionChange = (e) => {
      let value = e.target.value;
      this.setState({
        selectedOption: value
      });
    };

  handleCheckboxChange = (e) => {
    let currFormOption = this.state.formOption;
    let idxToUpdate = this.state.currFormData[currFormOption].findIndex(item => {
      return item.get('name') === e.target.name;
    });
    let isChecked = e.target.checked;
    let tmpCountOfChecked = this.state.countOfChecked;
    let tmpCurrCount = tmpCountOfChecked[this.state.formOption];
    tmpCurrCount = (isChecked) ? tmpCurrCount+1: tmpCurrCount-1;
    tmpCountOfChecked[this.state.formOption] = tmpCurrCount;
    let tmpCurrFormData = this.state.currFormData;
    tmpCurrFormData[currFormOption] = tmpCurrFormData[currFormOption].setIn([ idxToUpdate, 'isChecked' ], isChecked);
    this.setState({
      currFormData: tmpCurrFormData,
      countOfChecked: tmpCountOfChecked,
      isStepComplete: (tmpCurrCount > 0)
    });
  };

  prepareMapping(path) {
    this.props.loadFileContent({ path: path }).then(() => {
      this.props.registerMapping({ path: path, name: "currentProject" });
    });
  }

  saveChosenData = () => {
    const projectId = this.state.projectId;
    if (this.state.formOption === "namePlace") {
      const path = projectId + "/project/" + projectId + "/only/project.json";

      const projectjson = {
        "projectId": projectId,
        "name": this.state.projectName,
        "placeState": this.state.selectedOption,
        "isCompleteProject": false
      };

      const file = new File([
        new Blob([ JSON.stringify(projectjson, null, 2) ], { type: 'application/json' }) ],
      'project.json',
      { lastModified: new Date(0), type: 'application/json' });

      this.props.createSpace({ name: projectId, policy: "project" }).then(() => {
        this.props.createPackage({ spaceName: projectId, category: "project", subcategory: "regular",
          identifier: projectId, version: "only" }).then(() => {
          this.props.createFile({ file: file, path: path }).then(() => {
            this.prepareMapping(path);
            this.setState({
              formOption: "rooms", backFormOption: "namePlace",
              isStepComplete: (this.state.countOfChecked["rooms"] > 0), isReturnLastValues: false
            });
          });
        });
      });
    }
    else {
      let selectedItems;
      if (this.state.formOption === "rooms") {
        selectedItems = this.state.currFormData["rooms"].filter(item => item.get("isChecked") === true)
          .map((item) => {
            return Map({
              name: item.get("name"),
              value: item.get("value")
            });
          });

        let database = {
          "documents":{},
          "inspiration":{},
          "products":{}
        };
        database.documents["faktury"] = { catalogName: "Faktury", files: [] };
        database.documents["gwarancje"] = { catalogName: "Gwarancje", files: [] };
        database.documents["wizytowki"] = { catalogName: "Wizytówki", files: [] };
        database.documents["umowy"] = { catalogName: "Umowy", files: [] };
        database.documents["notatki"] = { catalogName: "Notatki", files: [] };
        database.documents["wizualizacje"] = { catalogName: "Wizualizacje", files: [] };
        database.documents["plany_techniczne"] = { catalogName: "Plany techniczne", files: [] };

        database.products["materialy_budowlane"] = { catalogName: "Materiały budowlane", subCatalogs: {} };
        selectedItems.map((item) => {
          database.inspiration[item.get("name")] = { catalogName: item.get("value"), files: [] };
          database.products[item.get("name")] = { catalogName: item.get("value"), subCatalogs: {} };
          return database;
        });

        this.props.addRoomsToNewProject({
          "projectId": projectId,
          "selectedItems": selectedItems,
          "database": database
        });
        this.setState({ formOption: "renovationWorks", backFormOption: "rooms",
          isStepComplete: (this.state.countOfChecked["renovationWorks"] > 0), isReturnLastValues: false });
      }
      else { //after click in RenovationWorks
        selectedItems = {};
        this.state.currFormData["renovationWorks"].filter(item => item.get("isChecked") === true)
          .forEach(function (item) {
            selectedItems[item.get("name")] = {
              value: item.get("value"),
              priceSupposed: 0,
              priceReal: 0,
              date: {
                start: new Date().toISOString().slice(0, 10),
                end: new Date().toISOString().slice(0, 10)
              }
            };
          });
        this.props.addRenovationWorksToNewProject({
          "projectId": projectId,
          "selectedItems": selectedItems,
          "isCompleteProject": true
        });
        notify.success("", this.props.t('msg.new_project_created'));
        this.props.history.push(`/project/${projectId}`);
      }
    }
  };

  renderFormNamePlaceState = (t) => {
    return (
      <Form>
        <FormGroup>
          <Label for="projectName">{t('project_creator.project_name')}</Label>
          <Input
            type="text"
            name="projectName"
            id="projectName"
            placeholder={t('project_creator.placeholder_project_name')}
            value={this.state.projectName}
            onChange={this.updateProjectName}
            autoFocus={true}/>
        </FormGroup>
        <FormGroup tag="fieldset">
          {this.props.newProjectData.get("placeState").map((item) => {
            return (
              <React.Fragment key={uuidv4()}>
                <p>{item.get("name")}</p>
                {item.get("states").map((item) => {
                  return (
                    <RadioVox
                      name="radio-select-house-or-flat-type"
                      value={item.get("value")}
                      text={item.get("text")}
                      selectedOption={this.state.selectedOption}
                      onChange={(e) => this.handleOptionChange(e)}
                      key={item.get("value")}/>
                  );
                })}
              </React.Fragment>
            );
          })}
        </FormGroup>
      </Form>
    );
  };

  getSelectedData = () => {
    let selectedData = [];
    this.state.currFormData[this.state.formOption].filter(item => item.get("isChecked") === true)
      .map((item) => {
        return selectedData.push(item.get("name"));
      });
    return selectedData;
  };

  renderFormCheckBoxList = (t, isSelectRooms) => {
    return (
      <Form>
        <FormGroup className="d-flex justify-content-between align-items-center">
          <p>{isSelectRooms ? t('project_creator.rooms') : t('project_creator.renovation_work')}:</p>
          <CustomButton action="add" color="grey" text={t('form.add')} icon="add"
            onClickFun={() => this.setState({ formOption: (isSelectRooms ? "addRoom": "addRenovationWorks"),
              backFormOption: (isSelectRooms ? "rooms": "renovationWorks"),
              selectedData: this.getSelectedData(), newDataItem: '' })} />
        </FormGroup>
        <FormGroup>
          {this.state.currFormData[this.state.formOption].map((item) => {
            return (
              <CheckBoxBtnVox
                name={item.get("name")}
                text={item.get("value")}
                key={uuidv4()}
                isSelected={item.get("isChecked")}
                onCheckboxChange={(e) => this.handleCheckboxChange(e)}
                isAddingContextMenu={item.get("isNew")}/>);
          })}
        </FormGroup>
      </Form>
    );
  };

  addNewElementToStateData = () => {
    let tmpData = this.state.currFormData;
    let tmpNewData = this.state.newData;
    let tmpCountOfChecked = this.state.countOfChecked;
    let tmpCurrCount = tmpCountOfChecked[this.state.backFormOption];
    tmpCurrCount+=1;
    tmpCountOfChecked[this.state.backFormOption] = tmpCurrCount;
    let newItem = fromJS({ name: uuidv4()+this.state.newDataItem, value: this.state.newDataItem, isChecked: true, isNew: true });
    tmpData[this.state.backFormOption] = tmpData[this.state.backFormOption].insert(tmpData[this.state.backFormOption].size, newItem);
    tmpNewData[this.state.backFormOption].push(newItem);
    this.setState({ formOption: this.state.backFormOption, backFormOption: this.state.formOption,
      currFormData: tmpData, newData: tmpNewData, countOfChecked: tmpCountOfChecked,
      isStepComplete: (tmpCurrCount > 0) });
  };

  renderFormAdd = (t, isRoomForm) => {
    return (
      <Form>
        <FormGroup>
          <Label for="newDataItem">{t('form.name') + (isRoomForm ? t('project_creator.rooms').toLowerCase()
            : t('project_creator.renovation_work2'))}:</Label>
          <Input
            type="text"
            name="newDataItem"
            id="newDataItem"
            value={this.state.newDataItem}
            onChange={this.updateNewDataItem}
            placeholder={t('form.enter_name')}
            autoFocus={true}/>
        </FormGroup>
      </Form>
    );
  };

  renderCurrForm = (t) => {
    if (this.state.formOption === "namePlace") {
      return this.renderFormNamePlaceState(t);
    }
    else if (this.state.formOption === "rooms") {
      return this.renderFormCheckBoxList(t, true);
    }
    else if (this.state.formOption === "renovationWorks") {
      return this.renderFormCheckBoxList(t, false);
    }
    else if (this.state.formOption === "addRoom") {
      return this.renderFormAdd(t, true);
    }
    else if (this.state.formOption === "addRenovationWorks") {
      return this.renderFormAdd(t, false);
    }
  };

  renderFooterButton = (t) => {
    let text = t("form.next");
    let tmpIsStepComplete = this.state.isStepComplete;
    if (this.state.formOption === "renovationWorks") {
      text = t('project_creator.create_project');
    }
    else if (this.state.formOption.includes("add")) {
      text = t('form.add');
    }

    if (this.state.formOption === "namePlace") {
      tmpIsStepComplete = (this.state.projectName !== "");
    }
    else if (this.state.formOption === "rooms") {
      tmpIsStepComplete = this.state.countOfChecked["rooms"] > 0;
    }
    else if (this.state.formOption === "renovationWorks") {
      tmpIsStepComplete = this.state.countOfChecked["renovationWorks"] > 0;
    }
    else if (this.state.formOption.includes("add")) {
      tmpIsStepComplete = (this.state.newDataItem !== "");
    }

    return (
      <CustomButton color="red" extraCssClass={"m-lr-693 " + (tmpIsStepComplete ? "" :"disable")} text={text}
        onClickFun={() => (this.state.formOption.includes("add") ? this.addNewElementToStateData() : this.saveChosenData())}
      />
    );
  };

  onClickedTopbarElem = () => {
    if (this.state.formOption === "namePlace") {
      this.props.history.goBack();
    }
    else if (this.state.formOption === "rooms") {
      this.setState({ formOption: "namePlace", isReturnLastValues: true });
    }
    else if (this.state.formOption === "renovationWorks") {
      this.setState({ formOption: "rooms", backFormOption: "namePlace", isReturnLastValues: true });
    }
    else if (this.state.formOption.includes("add")) {
      this.setState({ newDataItem: '', formOption: this.state.backFormOption });
    }
  };

  renderTopBar = (t) => {
    return (
      <Row className="topbar">
        <Col className="d-flex align-items-center justify-content-start">
          <img src={[ "namePlace", "addRoom", "addRenovationWorks" ].includes(this.state.formOption) ?
            importedIconClose : importedIconBack }
          alt="Icon close" className="img-icon cursor" onClick={() => this.onClickedTopbarElem()}/>
        </Col>
        <Col className="d-flex align-items-center justify-content-center">
          {this.state.formOption.includes("add") ?
            <p className="d-flex align-items-center font-medium">
              {this.state.formOption.includes("room") ? t('project_creator.title_add_new_room') : t('project_creator.title_add_new_renovation')}</p>
            : <img src={importedIconLogo} alt="Main logo icon." className="img-logo "/>}
        </Col>
        <Col className="d-flex align-items-center justify-content-end"/>
      </Row>
    );
  };

  //TODO: remove only for test
  onClickTest = () => {
    this.props.createTestProject();
    this.props.history.push("/project/test007");
  };

  render() {
    if (this.props.newProjectData) {
      const { t } = this.props;
      return (
        <Container className="project-creator">
          {this.renderTopBar(t)}
          <Col>
            {!this.state.formOption.includes("add") ? <>
              <TitleRow title={t('project_creator.title_create_project')} extraCssClass="main-title mb-32"/>
              <Row>
                <p>{t('project_creator.desc_create_project')}</p>
                {/*TODO: to test only:
                <p onClick={()=>this.onClickTest()}>test</p>*/}
              </Row>
            </> : null
            }
            <Row className="mt-4 mb-0">
              {this.renderCurrForm(t)}
            </Row>
            <Row className="footer-container pt-2">
              {this.renderFooterButton(t)}
            </Row>
          </Col>
        </Container>
      );
    }
    return null;
  }
}

CreateNewProject.propTypes = {
  addRoomsToNewProject: PropTypes.func,
  addRenovationWorksToNewProject: PropTypes.func,
  createNewProject: PropTypes.func,
  createTestProject: PropTypes.func,
  history: PropTypes.object,
  location: PropTypes.object,
  loadPlaceStateList: PropTypes.func,
  loadListOfRooms: PropTypes.func,
  loadListOfRenovationWorks: PropTypes.func,
  newProjectData: PropTypes.object,
  t: PropTypes.func,
  loadFileContent: PropTypes.func,
  registerMapping: PropTypes.func,
  testAction: PropTypes.func,
  ibisContent: PropTypes.object,
  createSpace: PropTypes.func,
  createPackage: PropTypes.func,
  createFile: PropTypes.func,
};
