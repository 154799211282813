import * as attributeActions from './attribute';
import * as fileActions from './file';
import * as auditActions from './audit';
import * as assignmentActions from './assignment';
import * as directoryActions from './content';
import * as cacheActions from './cache';
import * as mappingActions from './mapping';

export const namespace = '@IBIS_INTERNAL/';

export const SET_ATTRIBUTE = namespace + 'SET_ATTRIBUTE';
export const ADD_AUDIT = namespace + 'ADD_AUDIT';
export const ADD_AUDIT_ITEM = namespace + 'ADD_AUDIT_ITEM';
export const ADD_ASSIGNMENTS = namespace + 'ADD_ASSIGNMENTS';
export const ADD_ASSIGNMENT = namespace + 'ADD_ASSIGNMENT';
export const ADD_FILE_CONTENT = namespace + 'ADD_FILE_CONTENT';
export const ADD_DIRECTORY = namespace + 'ADD_DIRECTORY';
export const ADD_SUB_FILE = namespace + 'ADD_SUB_FILE';
export const APPLY_PATCH = namespace + 'APPLY_PATCH';
export const UPDATE_CACHE = namespace + 'UPDATE_CACHE';
export const SET_MAPPING = namespace + 'SET_MAPPING';
export const CLEAR_STORE = namespace + 'CLEAR_STORE';


const actions = {
  ...attributeActions,
  ...fileActions,
  ...auditActions,
  ...assignmentActions,
  ...directoryActions,
  ...cacheActions,
  ...mappingActions
};
export default actions;
