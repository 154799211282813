import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container, Row, Col, Input } from 'reactstrap';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import BarSearchMoreAdd from '../components/barSearchMoreAdd';
import EmptyFieldMessageElement from '../components/emptyFieldMessageElement';
import TitleRow from '../components/titleRow';
import BudgetGeneralCostBar from '../components/budgetGeneralCostBar';
import {
  changeWorkName, changePriceSupposed,
  changePriceReal, addNewRenovationWork,
  changeDate
} from '../actions/projectActions';
import ProblemWithProjectConfig from '../components/problemWithProjectConfig';

@withTranslation()
@connect(
  state => ({
    projectData: state.IBIS.cache.get('currentProject')
  }),
  dispatch => {
    return bindActionCreators({
      changeWorkName: changeWorkName,
      changePriceSupposed: changePriceSupposed,
      changePriceReal: changePriceReal,
      changeDate: changeDate,
      addNewRenovationWork: addNewRenovationWork
    }, dispatch);
  }
)
export default class Budget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wasUpdateInputValue: false,
      focusItem: ""
    };
  }

  showRenvationWorksFromList = (list) => {
    if (list !== undefined && list.size > 0) {
      let isRedFont;
      return list.map((item, key) => {
        isRedFont = (item.get('priceReal') > item.get('priceSupposed')) ? true : "";
        return (
          <Row className="work-elem mb-10" key={"k_" + Math.random()}>
            <Row>
              {this.createEditRowInput("textarea", "work-name flex-fill", key, item.get("value"), "name")}
              {this.createEditRowInput("number", "price-box", key, item.get("priceSupposed"), "p_sup")}
              <p className="price-supposed-currency font-medium">zł</p>
              {this.createEditRowInput("number", ("price-box " + (isRedFont ? "font-red" : "")), key, item.get("priceReal"), "p_real")}
              <p className={"price-real-currency font-medium " + (isRedFont ? "font-red" : "")}>zł</p>
            </Row>
            <Row className="w-100">
              <Col xs={6} className='p-0'>
                {this.createDateInput("date", key, item.getIn([ "date", "start" ]), "start")}
              </Col>
              <Col xs={6} className='p-0'>
                {this.createDateInput("date", key, item.getIn([ "date", "end" ]), "end")}
              </Col>
            </Row>
          </Row>
        );
      }).toSetSeq();
    }
  };

  createDateInput = (type, name, value, whichDate) => {
    return (
      <Input
        id={name + whichDate}
        name={name}
        type={type}
        onChange={(e) => {
          this.handleChangeDate(e, name, whichDate);
        }}
        value={value}
      />
    );
  }

  createEditRowInput = (type, cssMainClass, name, value, dataName) => {
    return (
      <Input
        id={name + dataName}
        type={type}
        className={cssMainClass + " box-share font-medium"}
        name={name}
        value={(value === "new") ? "" : value}
        placeholder={(value === "new") ? "Nadaj nazwę..." : ""}
        onChange={(e, inputValueName, id) => {
          this.handleChangeInput(e, dataName, (name + dataName));
        }}
        autoFocus={(this.state.focusItem === name + dataName)}
        onKeyDown={(e) => {
          if (value === 0) e.target.value = "";
          if (e.target.selectionStart === 0 && this.state.selectionStart !== undefined) { //used when user press multiple keys
            e.target.selectionStart = this.state.selectionStart;
            e.target.selectionEnd = this.state.selectionEnd;
          }
        }}
        onKeyUp={(e) => {
          if (this.state.focusItem === name + dataName && e.target.selectionStart !== null) {
            if (e.which < 37 || e.which > 40) { //arrows to move cursor left/right/top/down
              e.target.selectionStart = this.state.selectionStart;
              e.target.selectionEnd = this.state.selectionEnd;
            }
          }
        }}
      />
    );
  };

  handleChangeDate = (e, name, whichDate) => {
    let targetName = e.target.name;
    let targetValue = e.target.value;
    this.props.changeDate({
      forName: targetName,
      newValue: targetValue,
      whichDate: whichDate
    });
    this.setState({
      wasUpdateInputValue: true,
      focusItem: name + whichDate,
      selectionStart: e.target.selectionStart,
      selectionEnd: e.target.selectionEnd
    });
  };

  handleChangeInput = (e, inputValueName, id) => {
    let targetName = e.target.name;
    let targetValue = e.target.value;
    if (inputValueName === "name") {
      this.props.changeWorkName({
        forName: targetName,
        newValue: targetValue,
      });
    }
    else {
      targetValue = (targetValue === "" ? 0 : targetValue); //catch to error NaN
      targetValue = parseInt(targetValue, 10);
      e.target.value = targetValue; //update in input
      if (inputValueName === "p_sup") {
        this.props.changePriceSupposed({
          forName: targetName,
          newValue: targetValue
        });
      }
      else {
        this.props.changePriceReal({
          forName: targetName,
          newValue: targetValue
        });
      }
    }
    this.setState({
      wasUpdateInputValue: true,
      focusItem: id,
      selectionStart: e.target.selectionStart,
      selectionEnd: e.target.selectionEnd
    });
  };

  componentDidUpdate(prevProps) {
    if (this.state.wasUpdateInputValue) {
      this.setState({
        wasUpdateInputValue: false
      });
    }
  }

  onClickAddNewRenovationWork = () => {
    this.props.addNewRenovationWork("new");
  };

  render() {
    const { t } = this.props;
    if (!this.props.projectData.get("isCompleteProject")) {
      return <ProblemWithProjectConfig />;
    }
    else {
      const list = this.props.projectData.get("renovationWorks");
      if (list === undefined || list.size === 0) {
        return (<EmptyFieldMessageElement msg="Nie wybrano żadnej pracy remontowej." extraCssClass="m-lr-693" />);
      }
      else {
        return (
          <Container className="budget">
            <TitleRow title="Budżet" extraCssClass="main-title" />
            <BarSearchMoreAdd onClickAdd={() => this.onClickAddNewRenovationWork()} />
            <BudgetGeneralCostBar list={this.props.projectData.get("renovationWorks")} t={t} />
            <Row className="d-flex justify-content-between title-group">
              <p>Nazwa etapu</p>
              <p>Koszt: zakładany / rzeczywisty</p>
            </Row>
            <Row className="work-list">
              {this.showRenvationWorksFromList(list)}
            </Row>
          </Container>
        );
      }
    }
  }
}

Budget.propTypes = {
  projectData: PropTypes.object,
  addNewRenovationWork: PropTypes.func,
  changeWorkName: PropTypes.func,
  changePriceSupposed: PropTypes.func,
  changePriceReal: PropTypes.func,
  changeDate: PropTypes.func,
  t: PropTypes.func, //HOC
};