import React, { Component } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { Switch, Route, Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import PropTypes from 'prop-types';
import TitleRow from '../components/titleRow';
import BarSearchMoreAdd from '../components/barSearchMoreAdd';
import EmptyFieldMessageElement from '../components/emptyFieldMessageElement';
import Loading from '../components/loading';
import FormProductAction from './formProductAction';
import CatalogBox from '../components/catalogBox';
import importedIconBtnList from '../../assets/img/icons/button_list.svg';
import importedIconBtnTiles from '../../assets/img/icons/button_tiles.svg';
import ImagePreview from '../components/imagePreview';

@withTranslation()
@connect(
  state => ({
    projectData: state.IBIS.cache.get('currentProject')
  })
)
export default class ProductsBoard extends Component {
  state = {
    isShowAddMenu: false,
    isShowLoading: false,
    isListView: false
  };

  renderFilesAsCatalogBox = (files) => {
    return files.map((item, idx) => {
      return (
        <CatalogBox
          title={ { name: item.get("name"), price: item.get("price")+this.props.t('form.price_unit') } }
          imgPath={item.get("source")}
          extraCssClass="catalog-product mb-10"
          linkPathName={`${this.props.match.url}/edit`}
          linkState={{ editItem: item, itemIdx: idx }}
          key={uuidv4()}
          type="product"
        />
      );
    });
  };

  onClickProductRowList = (linkPathName, linkState, children) => {
    return (
      <Link to={{
        pathname: linkPathName,
        state: linkState }}>
        {children}
      </Link>
    );
  };

  renderFilesAsList = (files) => {
    return files.map((item, idx) => {
      return (
        <Link key={uuidv4()} to={{
          pathname: `${this.props.match.url}/edit`,
          state: { editItem: item, itemIdx: idx } }}>
          <Row className="box-share mt-2 py-2 px-3 d-flex product-list-row">
            <Col className="col-1 mr-2">
              <ImagePreview filePath={item.get("source")} className="img-icon-32" alt="icon-product"/>
            </Col>
            <Col className="flex-grow-1">
              <p className="font-medium">{item.get("name")}</p>
            </Col>
            <Col className="col-2 flex-grow-0 text-right">
              <p className="font-medium">{item.get("price")+this.props.t('form.price_unit')}</p>
            </Col>
          </Row>
        </Link>
      );
    });
  };

  renderProducts = (catalog) => {
    if (catalog.get("files").size === 0) {
      return (<EmptyFieldMessageElement msg={this.props.t('project.db_empty_products')}/>);
    }
    else {
      if (this.state.isListView) {
        return this.renderFilesAsList(catalog.get("files"));
      }
      else {
        return (
          <Row className={"w-100 " + (catalog.get("files").size > 0 ? "more-photos" : "")}>
            {this.renderFilesAsCatalogBox(catalog.get("files"))}
          </Row>
        );
      }
    }
  };

  changeTypeOfView = () => {
    this.setState((prevState) => ({
      isListView: !prevState.isListView
    }));
  };

  renderContent = () => {
    const { t } = this.props;
    const catalogData = this.props.projectData.getIn([ 'database', "products", this.props.match.params.catalog,
      "subCatalogs", this.props.match.params.subCatalog ]);
    return (
      <React.Fragment>
        <Row className="products">
          <Col>
            <TitleRow title={catalogData.get("catalogName")} extraCssClass="main-title"/>
            <BarSearchMoreAdd onClickAdd={() => this.setState({ isShowAddMenu: true })}
              onCenterBtnChange={this.handleChangeAddPhoto}/>
            {catalogData.get("files").size > 0 ?
              <Row className="my-3 d-flex justify-content-between">
                <p className="d-flex align-items-center">{t('products.all_products')}</p>
                <img src={(this.state.isListView ? importedIconBtnTiles : importedIconBtnList)}
                  className={"img-icon "+(catalogData.get("files").size === 0 ? "d-none": "")}
                  alt="icon-view-type" onClick={() => this.changeTypeOfView()}/>
              </Row>
              : null}
            {this.renderProducts(catalogData)}
          </Col>
        </Row>
        {this.state.isShowAddMenu &&
              <React.Fragment>
                <div className="dashboard-cover"/>
                <Row className="menu-add-file px-2">
                  <Col className="w-100">
                    <Button className="font-medium w-100" color="white"
                      onClick={() => {
                        this.props.history.push(this.props.location.pathname+"/add");
                        this.setState({ isShowAddMenu: false });
                      }}>
                      {t('form.add_item', { item: t('products.product') })}
                    </Button>
                    <Button className="font-medium disable" color="white">{t('project.paste_link')}</Button>
                    <Button className="font-medium w-100" color="white"
                      onClick={() => this.setState({ isShowAddMenu: false })}>{t('form.cancel')}</Button>
                  </Col>
                </Row>
              </React.Fragment>
        }
        {this.state.isShowLoading && <Loading/>}
      </React.Fragment>
    );
  };

  render() {
    return (
      <Switch>
        <Route exact path={this.props.match.path}>
          {this.renderContent()}
        </Route>
        <Route path={`${this.props.match.path}/add`} render={props => (
          <FormProductAction {...props} group="products" action="add"/>
        )}/>
        <Route path={`${this.props.match.path}/edit`} render={props => (
          <FormProductAction {...props} group="products" action="edit"/>
        )}/>
      </Switch>
    );
  }
}

ProductsBoard.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
  projectData: PropTypes.object,
  t: PropTypes.func
};
