import React from 'react';
import { Row } from 'reactstrap';
import PropTypes from 'prop-types';

export default function TitleRow(props) {
  let cssClassName = "font-medium mb-10 ";
  if (props.extraCssClass !== undefined) {
    cssClassName += props.extraCssClass;
  }

  return (
    <Row className="d-flex align-items-center justify-content-center mt-0">
      <p className={cssClassName}>{props.title}</p>
    </Row>
  );
}

TitleRow.propTypes = {
  extraCssClass: PropTypes.string,
  title: PropTypes.string,
};