import { ADD_ASSIGNMENTS, ADD_ASSIGNMENT } from '.';

export function addAssignments({ assignments, apiPath }) {
  return {
    type: ADD_ASSIGNMENTS,
    payload: { assignments, apiPath }
  };
}

export function addAssignment({ assignment, apiPath }) {
  return {
    type: ADD_ASSIGNMENT,
    payload: { assignment, apiPath }
  };
}