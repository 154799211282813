import React, { Component } from 'react';
import { Container } from 'reactstrap';

export default class Tips extends Component {
  render() {
    return (
      <Container className="tips">
        <p>...tips view...</p>
      </Container>
    );
  }
}