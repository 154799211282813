import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { bindActionCreators } from 'redux';
import { Col, Form, FormGroup, Label, Input, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import BarCloseSendSave from '../components/barCloseSendSave';
import { addFileToDatabase, deleteFileFromDatabase, editFileInDatabase } from '../actions/projectActions';
import ModalConfirmation from '../components/modalConfirmation';
import * as notify from '../../main/utils/notify';
import IBIS_actions from 'ibis-ui/ibis-client-middleware/interface';
import ImagePreview from '../components/imagePreview';


@withTranslation()
@withRouter
@connect(
  state => ({
    projectData: state.IBIS.cache.get('currentProject')
  }),
  dispatch => {
    return bindActionCreators({
      addFileToDatabase: addFileToDatabase,
      editFileInDatabase: editFileInDatabase,
      deleteFileFromDatabase: deleteFileFromDatabase,
      createFile: IBIS_actions.createFile,
    }, dispatch);
  }
)
export default class FormPhotoAction extends Component {
  state = {
    inputTitle: '',
    inputDesc: '',
    isModalConfirmShow: false
  };

  static getDerivedStateFromProps(props, state) {
    if (props.location.state.editItem && state.inputTitle === "") {
      return {
        inputTitle: props.location.state.editItem.get("name"),
        inputDesc: props.location.state.editItem.get("desc")
      };
    }
    return null;
  }

    updateInputTitle = (evt) => {
      this.setState({
        inputTitle: evt.target.value
      });
    };

    updateInputDesc = (evt) => {
      this.setState({
        inputDesc: evt.target.value
      });
    };

    onClickSave = () => {
      let urlParams = this.props.match.params;
      if (this.props.action === "add") {
        const newName = uuidv4().split('-').join('');
        const path = urlParams.projectId + "/project/" + urlParams.projectId + "/only/" + newName;
        const file = new File([
          new Blob([ this.props.location.state.fileSource ], { type: this.props.location.state.fileInfo.type }) ], newName,
        { lastModified: this.props.location.state.fileInfo.lastModified, type: this.props.location.state.fileInfo.type });
        this.props.createFile({ file: file, path: path }).then(() => {
          this.props.addFileToDatabase(Map({
            projectId: urlParams.projectId,
            group: this.props.group,
            catalogName: urlParams.catalog,
            fileData: Map({
              name: this.state.inputTitle,
              desc: this.state.inputDesc,
              source: path,
              fileInfo: Map({
                name: this.props.location.state.fileInfo.name,
                size: this.props.location.state.fileInfo.size,
                type: this.props.location.state.fileInfo.type,
                lastModified: this.props.location.state.fileInfo.lastModified,
                lastModifiedDate: this.props.location.state.fileInfo.lastModifiedDate
              })
            })
          }));
        });
      }
      else {
        let editItemData = this.props.location.state;
        this.props.editFileInDatabase(Map({
          projectId: urlParams.projectId,
          group: this.props.group,
          catalogName: urlParams.catalog,
          fileData: Map({
            name: this.state.inputTitle,
            desc: this.state.inputDesc,
            source: editItemData.editItem.get("source"),
            fileInfo: Map( {
              name: editItemData.editItem.get("fileInfo").name,
              size: editItemData.editItem.get("fileInfo").size,
              type: editItemData.editItem.get("fileInfo").type,
              lastModified: editItemData.editItem.get("fileInfo").lastModified,
              lastModifiedDate: editItemData.editItem.get("fileInfo").lastModifiedDate
            })
          }),
          fileId: editItemData.itemIdx }));
      }
      notify.success("", this.props.t('msg.inspiration_save_correctly'));
      this.props.history.goBack();
    };

  onClickDeleteInspiration = () => {
    let urlParams = this.props.match.params;
    this.props.deleteFileFromDatabase(Map({
      projectId: urlParams.projectId,
      group: this.props.group,
      catalogName: urlParams.catalog,
      fileId: this.props.location.state.itemIdx
    }));
    notify.success("", this.props.t('msg.inspiration_delete_correctly'));
    this.props.history.goBack();
  };

  render() {
    const { t } = this.props;
    let groupName = this.props.group;
    let fileSource;
    if (this.props.action === "add") {
      fileSource = new Blob([ this.props.location.state.fileSource ], { type: this.props.location.state.fileInfo.type });;
    }
    else {
      fileSource = this.props.location.state.editItem.get("source");
    }
    return (
      <Row>
        <Col>
          <BarCloseSendSave btnSaveColor="red" t={t}
            onClickBack={() => this.props.history.goBack()}
            onClickSave={() => this.onClickSave()}
            onClickDelete={() => this.setState({ isModalConfirmShow: true })}
            isAddDeleteBtn={this.props.action === "edit"}  />
          { this.props.action === "add" ?
            <img src={URL.createObjectURL(fileSource)} className="new-added-photo" alt="new-data"/> :
            <ImagePreview filePath={fileSource} className="new-added-photo" alt="new-data"/>
          }
          <Form>
            <FormGroup>
              <Label for="title">{t('project.new_photo_title', { label: t(groupName+'.'+groupName+'_i') })}</Label>
              <Input
                type="text"
                name="title"
                id="title"
                value={this.state.inputTitle}
                onChange={this.updateInputTitle}
                placeholder={t('project.new_photo_placeholder_title', { label: t(groupName+'.'+groupName+'_i') })}
                className="font-medium"
                autoFocus={true}/>
            </FormGroup>
            <FormGroup>
              <Label for="desc">{t('project.new_photo_desc', { label: t(groupName+'.'+groupName+'_i') })}</Label>
              <Input
                type="textarea"
                name="desc"
                id="desc"
                value={this.state.inputDesc}
                onChange={this.updateInputDesc}
                placeholder={t('project.new_photo_placeholder_desc', { label: t(groupName+'.'+groupName+'_i') })}
                className="input-desc"/>
            </FormGroup>
          </Form>
        </Col>
        {this.state.isModalConfirmShow ?
          <ModalConfirmation
            onClose={() => this.setState({ isModalConfirmShow: false })}
            onConfirm={() => {this.setState({ isModalConfirmShow: false }); this.onClickDeleteInspiration();}}
            onDecline={() => this.setState({ isModalConfirmShow: false }) }
            headerModal={t("inspiration.modal_remove_inspiration.title")}
            bodyModal={t("inspiration.modal_remove_inspiration.question")}
            yesText={ t('form.yes') }
            noText={ t('form.no') }
          />
          : null
        }
      </Row>
    );
  }
}

FormPhotoAction.propTypes = {
  action: PropTypes.string,
  addFileToDatabase: PropTypes.func,
  deleteFileFromDatabase: PropTypes.func,
  editFileInDatabase: PropTypes.func,
  group: PropTypes.string,
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
  t: PropTypes.func,
  createFile: PropTypes.func
};