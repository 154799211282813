import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { bindActionCreators } from 'redux';
import { Col, Form, FormGroup, Label, Input, Row, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { addProductToDatabase, editProductInDatabase, deleteProductFromDatabase } from '../actions/projectActions';
import * as notify from '../../main/utils/notify';
import Loading from '../components/loading';
import importedIconAdd from '../../assets/img/icons/icon_add.svg';
import importedIconMenu from '../../assets/img/icons/icon_menu.svg';
import ModalConfirmation from '../components/modalConfirmation';
import BarCloseSendSave from '../components/barCloseSendSave';
import IBIS_actions from 'ibis-ui/ibis-client-middleware/interface';
import ImagePreview from '../components/imagePreview';


@withTranslation()
@withRouter
@connect(
  state => ({
    projectData: state.IBIS.cache.get('currentProject')
  }),
  dispatch => {
    return bindActionCreators({
      addProductToDatabase: addProductToDatabase,
      editProductInDatabase: editProductInDatabase,
      deleteProductFromDatabase: deleteProductFromDatabase,
      createFile: IBIS_actions.createFile,
    }, dispatch);
  }
)
export default class FormProductAction extends Component {
  state = {
    inputName: '',
    inputPrice: '',
    isAddToBudget: false,
    fileSource: null,
    fileInfo: null,
    isShowLoading: false,
    isModalConfirmShow: false,
    isModalDeleteProduct: false,
    modalHeader: "",
    modalBody: ""
  };

  static getDerivedStateFromProps(props, state) {
    if (props.location.state && props.location.state.editItem && state.inputName === "") {
      return {
        inputName: props.location.state.editItem.get("name"),
        inputPrice: props.location.state.editItem.get("price"),
        fileSource: props.location.state.editItem.get("source"),
        fileInfo: props.location.state.editItem.get("fileInfo"),
      };
    }
    return null;
  }

  updateInputName = (evt) => {
    this.setState({
      inputName: evt.target.value
    });
  };

  updateInputPrice = (evt) => {
    this.setState({
      inputPrice: evt.target.value
    });
  };

  onClickSave = () => {
    let urlParams = this.props.match.params;
    const newName = uuidv4().split('-').join('');
    const path = urlParams.projectId + "/project/" + urlParams.projectId + "/only/" + newName;
    const file = new File([
      new Blob([ this.state.fileSource ], { type: this.state.fileInfo.type }) ], newName,
    { lastModified: this.state.fileInfo.lastModified, type: this.state.fileInfo.type });
    this.props.createFile({ file: file, path: path }).then(() => {
      let productData = {
        projectId: urlParams.projectId,
        group: this.props.group,
        catalog: urlParams.catalog,
        subCatalog: urlParams.subCatalog,
        newProduct: Map({
          name: this.state.inputName,
          price: this.state.inputPrice,
          source: path,
          fileInfo: Map({
            name: this.state.fileInfo.name,
            size: this.state.fileInfo.size,
            type: this.state.fileInfo.type,
            lastModified: this.state.fileInfo.lastModified,
            lastModifiedDate: this.state.fileInfo.lastModifiedDate
          })
        }) };

      if (this.props.action === "add") {
        this.props.addProductToDatabase(Map(productData));
      }
      else {
        productData['fileId'] = this.props.location.state.itemIdx;
        this.props.editProductInDatabase(Map(productData));
      }
    });

    //TODO: add to budget if user set it

    notify.success("", this.props.t('msg.product_save_correctly'));
    this.props.history.goBack();
  };

  onConfirmDeleteProduct = (t) => {
    this.setState({ isModalConfirmShow: true,
      isModalDeleteProduct: true,
      modalHeader: t("products.modal_remove_product.title"),
      modalBody: t("products.modal_remove_product.question") });
  };

  onConfirmDeleteImageOfProduct = (t) => {
    this.setState({ isModalConfirmShow: true,
      isModalDeleteProduct: false,
      modalHeader: t("products.modal_change_image.title"),
      modalBody: t("products.modal_change_image.question") });
  };

  onConfirmModal = () => {
    if (this.state.isModalDeleteProduct) {
      this.onClickDeleteProduct();
    }
    else {
      this.setState({ fileSource: null, fileInfo: null });
    }
    this.setState({ isModalConfirmShow: false });
  };

  onClickDeleteProduct = () => {
    let urlParams = this.props.match.params;
    this.props.deleteProductFromDatabase(Map({
      projectId: urlParams.projectId,
      group: this.props.group,
      catalog: urlParams.catalog,
      subCatalog: urlParams.subCatalog,
      fileId: this.props.location.state.itemIdx }));

    notify.success("", this.props.t('msg.product_delete_correctly'));
    this.props.history.goBack();
  };

  handleChangeAddPhoto= (event) => {
    this.setState({ isShowLoading: true });
    event.preventDefault();
    let file = event.target.files[0];
    let reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onloadend = () => {
      this.setState({ isShowLoading: false, fileSource: reader.result, fileInfo: {
        name: file.name,
        size: file.size,
        type: file.type,
        lastModified: file.lastModified,
        lastModifiedDate: file.lastModifiedDate.toLocaleString()
      } });
    };
  };

  renderProductImgOrButton = () => {
    if (this.state.fileSource) {
      if ( typeof this.state.fileSource === 'string' ) {
        return ( <ImagePreview filePath={this.state.fileSource} className="product-new-image" alt="new-data"/> );
      }
      else {
        const fileSource = new Blob([ this.state.fileSource ], { type: this.state.fileInfo.type });
        return ( <img src={URL.createObjectURL(fileSource)} className="product-new-image" alt="new-data"/> );
      }
    }
    else {
      return (
        <Button className="d-flex align-items-center justify-content-center font-medium cursor btn-with-icon btn-grey cursor">
          <input id="refBtnAddPhoto" type="file" accept="image/*" onChange={this.handleChangeAddPhoto} className="hideElement"/>
          <img src={importedIconAdd} alt="action-text"/>
          <label htmlFor="refBtnAddPhoto" className="mb-0 pr-2 cursor">{this.props.t('form.add')}</label>
        </Button>
      );
    }
  };

  render() {
    const { t } = this.props;
    let groupName = this.props.group;
    return (
      <Row>
        <Col>
          <BarCloseSendSave btnSaveColor="red" t={t}
            onClickBack={() => this.props.history.goBack()}
            onClickSave={() => this.onClickSave()}
            onClickDelete={() => this.onConfirmDeleteProduct(t)}
            isAddDeleteBtn={this.props.action === "edit"}
            centerTitle={(this.props.action === "add" ? t('form.add_item', { item: t('products.product') }) : null)}/>
          <Row>
            <Col className="d-flex flex-column align-items-center mt-5 mb-4">
              <Row className="product-img-menu d-flex justify-content-end">
                <Button className={"btn-transparent"+(!this.state.fileSource ? " disable": "")}
                  onClick={() => this.onConfirmDeleteImageOfProduct(t)}>
                  <img src={importedIconMenu} className="img-icon" alt="menu-icon"/>
                </Button>
              </Row>
              <Row>
                <div className="box-share product-box-shadow">
                  {this.renderProductImgOrButton()}
                </div>
              </Row>
            </Col>
          </Row>
          <Row>
            <Form>
              <FormGroup>
                <Label for="title">{t('form.name')}</Label>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  value={this.state.inputName}
                  onChange={this.updateInputName}
                  placeholder={t('project.new_photo_placeholder_name', { label: t(groupName+'.'+groupName+'_i') })}
                  className="font-medium"
                  autoFocus={true}/>
              </FormGroup>
              <FormGroup>
                <Label for="desc">{t('products.price')}</Label>
                <Input
                  type="number"
                  min="0"
                  step="0.01"
                  name="price"
                  id="price"
                  value={this.state.inputPrice}
                  onChange={this.updateInputPrice}
                  placeholder={t('project.new_product_price')}
                  className="font-medium"/>
              </FormGroup>
              {/*TODO: add-to-budget: brak ikonki
              <FormGroup>
                <Label for="desc">{t('products.add_to_budget')}</Label>
              </FormGroup>
              */}
            </Form>
            {this.state.isShowLoading && <Loading/>}
          </Row>
        </Col>
        {this.state.isModalConfirmShow ?
          <ModalConfirmation
            onClose={() => this.setState({ isModalConfirmShow: false })}
            onConfirm={() => this.onConfirmModal()}
            onDecline={() => this.setState({ isModalConfirmShow: false }) }
            headerModal={this.state.modalHeader}
            bodyModal={this.state.modalBody}
            yesText={ t('form.yes') }
            noText={ t('form.no') }
          />
          : null
        }
      </Row>
    );
  }
}

FormProductAction.propTypes = {
  action: PropTypes.string,
  addProductToDatabase: PropTypes.func,
  deleteProductFromDatabase: PropTypes.func,
  editProductInDatabase: PropTypes.func,
  group: PropTypes.string,
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
  t: PropTypes.func,
  createFile: PropTypes.func
};