import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { loadFileContent } from 'ibis-ui/ibis-client-middleware/ibis-client-functions';
import { predefinedImgSourceFromType } from '../utils/projectFunc';

const ImagePreview = props => {
  const [ image, setImage ] = useState(undefined);
  const mountedRef = useRef(true);

  useEffect(() => {
    if ((props.filePath) && !(props.fileInfo && props.fileInfo.type && !props.fileInfo.type.startsWith('image/'))) {
      loadFileContent(props.filePath, undefined, 'blob')
        .then(image => {
          if (!mountedRef.current) return null;
          setImage(image);
        });
      return () => {
        mountedRef.current = false;
      };
    }
  }, [ props ]);

  if (props.fileInfo && props.fileInfo.type && !props.fileInfo.type.startsWith('image/')) {
    return (<img src={ predefinedImgSourceFromType(props.fileInfo) } className={props.className} alt={props.alt}/>);
  }
  return (
    <>
      {
        image &&
          <img src={URL.createObjectURL(image)} className={props.className} alt={props.alt}/>
      }
    </>
  );
};

ImagePreview.propTypes = {
  filePath: PropTypes.string,
  className: PropTypes.string,
  alt: PropTypes.string,
  fileInfo: PropTypes.object
};

export default ImagePreview;