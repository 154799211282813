import React from 'react';
import EmptyFieldMessageElement from './emptyFieldMessageElement';
import LinkBtn from './linkBtn';
import { Col, Row } from 'reactstrap';

export default function ProblemWithProjectConfig(props) {
  return (
    <Row className="errors-in-project">
      <Col>
        <EmptyFieldMessageElement msg="Projekt nie został poprawnie skonfigurowany."
          extraCssClass="m-lr-693 text-center"/>
        <LinkBtn to="/"
          color="red"
          extraCssClass="btn-register"
          text="Wróć do strony początkowej"/>
      </Col>
    </Row>
  );
}

