import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import PropTypes from 'prop-types';

import importedIconSave_white from '../../assets/img/icons/icon_pin_white.svg';
import importedIconClose from '../../assets/img/icons/icon_close.svg';
import importedIconDelete from '../../assets/img/icons/icon_delete.svg';

export default function BarCloseSendSave({ centerTitle, isAddDeleteBtn, onClickDelete, onClickBack, onClickSave, t }) {
  return (
    <Row className="top-menu-bar-container menu-group d-flex justify-content-between py-3">
      <Col className="d-flex align-items-center justify-content-start">
        <img src={importedIconClose} alt="Icon back." className="img-icon" onClick={onClickBack}/>
      </Col>
      {addCenterTitle(centerTitle)}
      <Col className="d-flex justify-content-end">
        {addDeleteBtn(isAddDeleteBtn, onClickDelete, t)}
        <Button className="font-medium btn-with-icon d-flex align-items-center btn-red" onClick={onClickSave}>
          <img src={importedIconSave_white} alt="action-text" className="img-btn"/>
          <span>{t('form.save')}</span>
        </Button>
      </Col>
    </Row>
  );
}

function addCenterTitle(centerTitle) {
  if (centerTitle) {
    return (
      <Col className="d-flex align-items-center justify-content-center">
        <p className="d-flex align-items-center font-medium">{centerTitle}</p>
      </Col>
    );
  }
}

function addDeleteBtn(isAddDeleteBtn, onClickDelete, t) {
  if (isAddDeleteBtn) {
    return (
      <Button className="font-medium btn-with-icon d-flex align-items-center" onClick={onClickDelete}>
        <img src={importedIconDelete} alt="action-text" className="img-btn"/>
        <span>{t('form.remove')}</span>
      </Button>
    );
  }
}

BarCloseSendSave.propTypes = {
  centerTitle: PropTypes.string,
  onClickBack: PropTypes.func,
  isAddDeleteBtn: PropTypes.bool,
  onClickDelete: PropTypes.func,
  onClickSave: PropTypes.func,
  t: PropTypes.func
};