import { namespace } from '.';

export const LOAD_PLACE_STATE_LIST = namespace + 'LOAD_PLACE_STATE_LIST';
export const LOAD_LIST_OF_ROOMS = namespace + 'LOAD_LIST_OF_ROOMS';
export const LOAD_LIST_OF_RENOVATION_WORKS = namespace + 'LOAD_LIST_OF_RENOVATION_WORKS';


//Below function must be changed when api will be available.
export function loadPlaceStateList() {
  return {
    type: LOAD_PLACE_STATE_LIST,
    payload: placeStateList
  };
}

export function loadListOfRooms() {
  return {
    type: LOAD_LIST_OF_ROOMS,
    payload: listOfRooms
  };
}

export function loadListOfRenovationWorks() {
  return {
    type: LOAD_LIST_OF_RENOVATION_WORKS,
    payload: listOfRenovationWorks
  };
}

const placeStateList = [ {
  name: "Dom",
  states: [
    {
      value: "radio_house_raw_open",
      text: "stan surowy otwarty"
    }, {
      value: "radio_house_raw_close",
      text: "stan surowy zamknięty"
    }, {
      value: "radio_house_development",
      text: "stan deweloperski"
    }, {
      value: "radio_house_renovation",
      text: "remont domu"
    }
  ]
}, {
  name: "Mieszkanie",
  states: [ {
    value: "radio_flat_development",
    text: "stan deweloperski"
  }, {
    value: "radio_flat_ready_under_key",
    text: "mieszkanie pod klucz"
  }, {
    value: "radio_flat_renovation",
    text: "remont mieszkania"
  } ]
} ];

const listOfRooms = [ {
  name: "kuchnia",
  value: 'Kuchnia',
  isChecked: false
}, {
  name: "lazienka",
  value: 'Łazienka',
  isChecked: false
}, {
  name: "toaleta",
  value: 'Toaleta',
  isChecked: false
}, {
  name: "pokoj_dzienny",
  value: 'Pokój dzienny',
  isChecked: false
}, {
  name: "biuro",
  value: 'Biuro',
  isChecked: false
}, {
  name: "jadalnia",
  value: 'Jadalnia',
  isChecked: false
}, {
  name: "pokoj_dziecka",
  value: 'Pokój dziecka',
  isChecked: false
}, {
  name: "salon",
  value: 'Salon',
  isChecked: false
}, {
  name: "sypialnia",
  value: 'Sypialnia',
  isChecked: false
} ];

const listOfRenovationWorks = [ {
  name: "forging_tiles",
  value: 'Skuwanie płytek',
  isChecked: false
}, {
  name: "laying_tiles",
  value: 'Kładzenie płytek',
  isChecked: false
}, {
  name: "removing_floors",
  value: 'Zdejmowanie podłóg',
  isChecked: false
}, {
  name: "paint_walls",
  value: 'Malowanie ścian',
  isChecked: false
}, {
  name: "removing_wallpapers",
  value: 'Zdejmowanie tapet',
  isChecked: false
}, {
  name: "replace_gas_installation",
  value: 'Wymiana instalacji gazowej',
  isChecked: false
}, {
  name: "replace_water_installation",
  value: 'Wymiana instalacji wodnej',
  isChecked: false
}, {
  name: "move_wall",
  value: 'Przenoszenie ścian',
  isChecked: false
}, {
  name: "plastering_walls",
  value: 'Gipsowanie ścian',
  isChecked: false
}, {
  name: "replace_windows",
  value: 'Wymiana okien',
  isChecked: false
}, {
  name: "replace_pipes",
  value: 'Wymiana rur',
  isChecked: false
}, {
  name: "replace_door",
  value: 'Wymiana drzwi',
  isChecked: false
} ];