import qs from 'qs';

import { __env } from '../envloader';

export function checkForErrors(value) {
  if (value === undefined) {
    throw Error('Provided value is undefined, check if provided arguments are correct');
  }
  return value;
}

export class IbisError extends Error {
  constructor(message, axiosOriginal) {
    super(message);
    this.name = this.constructor.name;
    this.axiosOriginal = axiosOriginal;
    this.stack = this.axiosOriginal.stack;
    this.response = this.axiosOriginal.response;
    this.status = (this.axiosOriginal.response ? this.axiosOriginal.response.status : undefined);
  }
}

class Entity {

  constructor(client) {
    this.client = client;
  }

  sendRequest = ({
    method = "get",
    path = undefined,
    contentType = undefined,
    content = undefined,
    format = undefined,
    query = undefined,
    responseType = undefined
  } = {}) => {

    if (path === undefined) {
      if (this.apiPath === undefined) {
        throw Error('apiPath is undefined');
      }
      path = this.apiPath;
    }

    const boundSendRequest = (resolve, reject, remainingRetries) => {
      this.client.sendSingleRequest({
        method: method,
        path: path,
        content: content,
        contentType: contentType,
        format: format,
        query: query,
        responseType: responseType
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          const isNetworkError = !error.response && !error.status;
          const is5XX = error.status >= 500 && error.status < 600;

          if (__env.IBIS_CLIENT_NETWORK_ERROR_RETRY && isNetworkError && remainingRetries > 0) {
            //Retry after network error
            boundSendRequest(resolve, reject, remainingRetries - 1);
          }
          else if (__env.IBIS_CLIENT_5XX_ERROR_RETRY && is5XX && remainingRetries > 0) {
            //Retry after 5xx error
            boundSendRequest(resolve, reject, remainingRetries - 1);
          }
          else {
            reject(error);
          }

        });
    };

    const retries = (method === "get" || method === "head" ? this.client._defaultRetries : 0);

    return new Promise((resolve, reject) => {
      boundSendRequest(resolve, reject, retries);
    });
  };

  head = () => {
    return this.sendRequest({ method: "head", path: this.metaPath, format: "raw" })
      .then(response => {
        return response;
      });
  };

  exists = () => {
    return this.sendRequest({ method: "head", path: this.metaPath, format: "raw" })
      .then(() => {
        return true;
      }).catch(() => {
        //TODO: When the CORS problems have been resolved we should check here for proper status code
        return false;
      });
  };

  fetchAttributes = () => {
    return new Promise((resolve, reject) => {
      this.sendRequest({ method: "get", path: "_meta/schema/attributes/", format: "raw" })
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  create = () => {
    const content = this._extractData();
    return this.sendRequest({ method: 'post', path: this.apiPath, content: content, contentType: 'application/json' });
  };

  delete = () => {
    return this.sendRequest({ method: 'delete', path: this.apiPath })
      .then(response => {
        return response;
      });
  };

  exists = () => {
    return this.head()
      .then(boundResource => {
        return true;
      })
      .catch(error => {
        if (error.status === 404) {
          return false;
        }
        else {
          throw error;
        }
      });
  };

}

class Collection extends Entity {

  constructor(client) {
    super(client);
    this.categories = [];
  }

  len = () => {
    return this.sendRequest({ format: "raw" })
      .then(response => {
        return response.data.count;
      });
  };

  fetch = (query = false, getAll = false) => {
    return new Promise((resolve, reject) => {
      const rejection = (error) => {
        reject(error);
      };
      this._sendRequestForMore([], query, getAll, resolve, rejection);
    });
  }

  _sendRequestForMore = (currentResult, query, getAll, resolve, reject) => {
    Object.keys(query).forEach((key) => (!query[key]) && delete query[key]);
    query.format = "raw";

    let path = this.apiPath;
    this.sendRequest({ path, query })
      .then(response => {
        checkForErrors(response.data.items);
        const items = currentResult.concat(response.data.items.map(item => this.bind(item)));
        if (!getAll) {
          //Load one page
          let result = response.data;
          result.items = items;
          resolve(result);
        }
        else {
          //Load all results
          if (response.data.more) {
            query = qs.parse(response.data.more, { ignoreQueryPrefix: true });
            this._sendRequestForMore(items, query, true, resolve, reject);
          }
          else {
            let result = response.data;
            result.items = items;
            resolve(result);
          }
        }
      })
      .catch(error => {
        reject(error);
      });

  };

}

class Resource extends Entity {

}

class Audit extends Resource {
  constructor(directory, data) {
    super(directory.client);
    this.directory = directory;

    this.created = checkForErrors(data.created);
    this.status_code = checkForErrors(data.status_code);
    this.reason_phrase = checkForErrors(data.reason_phrase);
    this.user = checkForErrors(data.user);
    this.action = checkForErrors(data.action);
    this.context = checkForErrors(data.context);
  }

}

class Audits extends Collection {
  constructor(directory) {
    super(directory.client);
    this.client = directory.client;
    this.directory = directory;
    this.directoryPath = directory.apiPath;
  }

  get apiPath() {
    return this.directoryPath + '_meta/audit/';
  }

  bind = (data) => {
    return new Audit(this.directory, data);
  };
}

class Assignment extends Resource {
  constructor(directory, data) {
    super(directory.client);
    this.directory = directory;

    this.kind = checkForErrors(data.kind);
    this.name = checkForErrors(data.name);
    this.role = checkForErrors(data.role);
  }
}

class Assignments extends Collection {
  constructor(directory) {
    super(directory.client);
    this.client = directory.client;
    this.directory = directory;
    this.directoryPath = directory.apiPath;
  }

  get apiPath() {
    return this.directoryPath + '_meta/assignments/';
  }

  bind = (data) => {
    return new Assignment(this.directory, data);
  };
}

class Directory extends Collection {
  constructor(client, path, data = { name: '', attributes: {}, type: 'directory', items: [], more: null }) {
    super(client);
    this.client = client;
    this._apiPath = checkForErrors(path);

    this.name = data.name;
    this.items = data.items;
    this.attributes = data.attributes;
    this.more = data.more;
    this.type = data.type;

    this.audits = new Audits(this);
    this.assignments = new Assignments(this);
  }

  get apiPath() {
    return this._apiPath;
  }

  bind = (data) => {
    if (data.type === 'directory') {
      return new SubDirectory(this, data);
    }
    if (data.type === 'file') {
      return new File(this, data);
    }
  };
}

class File extends Resource {

  constructor(directory, data) {
    super(directory.client);
    this.directory = directory;
    this.type = 'file';

    this.attributes = checkForErrors(data.attributes);
    this.content_type = checkForErrors(data.content_type);
    this.name = checkForErrors(data.name);
    this.size = checkForErrors(data.size);
    this.user_content_last_modified = checkForErrors(data.user_content_last_modified);

    this.content = data.content;

    this._apiPath = this.directory.apiPath + data.name;
  }

  get apiPath() {
    return this._apiPath;
  }

  getFileContent = (responseType = null) => {
    return new Promise((resolve, reject) => {
      if (!responseType && this.content_type.substr(0, this.content_type.indexOf('/')) === 'image') {
        responseType = 'blob';
      }
      this.sendRequest({ method: "get", path: this.apiPath, format: "raw", responseType: responseType })
        .then(response => {
          let result = response.data;
          if (this.content_type.substr(0, this.content_type.indexOf('/')) === 'image') {
            result = response.data;
            resolve(result);
          }
          resolve(result);
        })
        .catch(error => {
          reject(error);
        });
    });
  };


  patch = () => {
    let formData = new FormData();
    formData.append('content',
      new Blob([ JSON.stringify(this.content, null, 2) ], { type: this.content_type }));

    return this.sendRequest({ method: 'patch', path: this.apiPath, content: formData, contentType: 'multipart/form-data' })
      .then(response => {
        return response;
      });
  };

  create = ({ content, content_type }) => {
    return this.sendRequest({ method: 'post', path: this.apiPath, content: content, contentType: content_type });
  }
}

class SubDirectory extends Resource {
  constructor(rootDirectory, data) {
    super(rootDirectory.client);
    this.rootDirectory = rootDirectory;
    this.client = rootDirectory.client;
    this._apiPath = rootDirectory.apiPath + data.name + '/';
    this.type = 'directory';

    this.name = checkForErrors(data.name);
    this.attributes = checkForErrors(data.attributes);
    this.type = checkForErrors(data.type);
  }

  get apiPath() {
    return this._apiPath;
  }

  _extractData = () => {
    const data = {};
    data.attributes = this.attributes;
    return data;
  }
}

export class Client {
  constructor({ tenant_url, token_provider, getSecret, isLoggedIn, axiosInstance }) {
    this.tenant_url = tenant_url;
    this.tokenProvider = token_provider;
    this.getSecret = getSecret;
    this.isLoggedIn = isLoggedIn;
    this.apiPath = "";
    this.entity = new Entity(this);
    this._axiosInstance = axiosInstance;
  };

  bind = (path, data) => {
    return new Directory(this, path, data);
  }

  sendSingleRequest = ({ method, path, contentType, content, format, query, retryCount = 0, responseType }) => {

    const finalQuery = (query !== undefined ? { ...query } : {});
    if (format !== undefined) {
      finalQuery["format"] = format;
    }
    const fullAddress = this.tenant_url + path + (Object.keys(finalQuery).length > 0 ? ("?" + qs.stringify(finalQuery)) : "");
    const headers = {};

    if (contentType !== undefined) {
      headers['Content-Type'] = contentType;
    }

    const secret = this.getSecret();
    const loggedIn = this.isLoggedIn();

    if (secret) {
      headers['X-ibis-Secret'] = secret;
    }
    if (!loggedIn || secret) {
      return new Promise((resolve, reject) => {
        this._axiosInstance({
          method: method,
          url: fullAddress,
          data: content,
          headers: headers,
          responseType: responseType
        })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(new IbisError("failure", error));
          });
      });
    }
    return this.tokenProvider.obtainToken(this._axiosInstance)
      .then(token => {
        headers['Authorization'] = "Bearer " + token;

        return new Promise((resolve, reject) => {
          this._axiosInstance({
            method: method,
            url: fullAddress,
            data: content,
            headers: headers,
            responseType: responseType
          })
            .then(response => {
              resolve(response);
            })
            .catch(error => {
              if (error.response && error.response.status === 401 && retryCount < 3) {
                // ibisToken expired - refresh ibisToken
                return this.tokenProvider.obtainToken(this._axiosInstance, true)
                  .then(token => {
                    // new accessToken obtained - send last request again
                    new Promise(function (resolve) {
                      setTimeout(resolve.bind(null, {}), 1000 * retryCount);
                    }).then(() => {
                      retryCount += 1;
                      this.sendSingleRequest({ method, path, contentType, content, format, query, retryCount, responseType })
                        .then(response => {
                          resolve(response);
                        });
                    });
                    //new ibisToken obtained - send last request again
                  });
              }
              else {
                reject(new IbisError("failure", error));
              }
            });
        });
      });
  };
}
