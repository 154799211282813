import { combineReducers } from 'redux';

import { LOG_IN_SUCCESS } from '../../oidc-middleware/actions/login';
import { attributeReducer } from './attribute-reducer';
import { directoryReducer } from './content-reducer';
import { mappingReducer } from './mapping-reducer';
import { cacheReducer } from './cache-reducer';

const combinedIBISReducers = combineReducers({
  attributes: attributeReducer,
  content: directoryReducer,
  mapping: mappingReducer,
  cache: cacheReducer
});

export const IBISReducers = (state, action) => {
  if (action.type === LOG_IN_SUCCESS) {
    state = undefined;
  }

  return combinedIBISReducers(state, action);
};