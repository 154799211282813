import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import TipsMiniView from './tipsMiniView';
import CreateNewProject from './createNewProject';
import Footer from './footer';
import Project from './project';
import Tips from './tips';
import Profile from './profile';
import Login from '../../main/containers/login';
import TitleRow from '../components/titleRow';
import LinkBtn from '../components/linkBtn';

@withTranslation()
@connect(
  state => ({
    loginStore: state.login.get('loginData'),
  }),

)
export default class Main extends Component {

  renderContent = () => {
    return (<Row>
      <Col>
        <TitleRow title={this.props.t('main.no_project_in_database')} extraCssClass="mt-36"/>
        <LinkBtn to='/createnew' color="red"
          text={this.props.t('main.click_here_to_create_new_project')}/>
      </Col>
    </Row>);
    /*if (this.props.stateData.size === 0) { //TODO: tutaj zczytac czy jest jakis projekt w bazie
        return <Col>
          <TitleRow title={this.props.t('main.no_project_in_database')} extraCssClass="mt-36"/>
          <Row>
            <LinkBtn to='/project/create' color="red"
              text={this.props.t('main.click_here_to_create_new_project')}/>
          </Row>
        </Col>;
      }
    */
  };


  render() {
    if (!this.props.loginStore) {
      return (
        <>
          <Login location={this.props.location} />
          <TipsMiniView/>
        </>
      );
    }
    else {
      return (
        <Switch>
          <Route exact strict path='/'>
            {this.renderContent()}
            <TipsMiniView/>
          </Route>
          <Route strict path='/createnew' component={CreateNewProject}/>
          <Route strict path='/project/:projectId' render={props => (
            <React.Fragment>
              <Project {...props}/>
              <Footer {...props}/>
            </React.Fragment>
          )}/>
          <Route exact strict path='/tips' component={Tips}/>
          <Route exact strict path='/profile' component={Profile}/>
        </Switch>
      );
    }
  }
}

Main.propTypes = {
  stateData: PropTypes.object,
  location: PropTypes.object,
  loginStore: PropTypes.object,
  t: PropTypes.func
};
