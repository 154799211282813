import shajs from 'sha.js';
import URLSafeBase64 from 'urlsafe-base64';
import randomstring from 'randomstring';
import qs from 'qs';

import { __env } from '../../envloader';
import { getAuthConfig, loginCallbackPath } from '../../main/utils/AuthConfigProvider';
import { clearOwnLS, setLS } from '../../main/utils/localStorageProvider';
import { getLogger } from '../../main/utils/logger';

export function getRedirectUri() {
  const urlArray = window.location.href.split("/");
  return urlArray[0] + "//" + urlArray[2] + loginCallbackPath;
}

export function logInRedirect(prompt) {
  clearOwnLS();
  // preserve url before login callback
  setLS('redirect_url_pathname', window.location.pathname);
  setLS('redirect_url_search', window.location.search);

  const redirect_uri = getRedirectUri();
  const client_id = __env.AUTH_CLIENT_ID;

  setLS('login_in_progress', true);
  const state = randomstring.generate();
  setLS('state', state);
  const code_verifier = randomstring.generate(64);
  setLS('code_verifier', code_verifier);
  const code_challenge = URLSafeBase64.encode(shajs('sha256').update(code_verifier).digest());

  const params = {
    prompt: prompt,
    response_type: 'code',
    scope: 'openid profile email',
    client_id: client_id,
    redirect_uri: redirect_uri,
    state: state,
    code_challenge: code_challenge,
    code_challenge_method: 'S256'
  };

  getAuthConfig().then((config) => {
    window.location.assign(config.AUTH_AUTHORIZATION_ENDPOINT + "?" + qs.stringify(params));
  }).catch(error => {
    getLogger({ loggerName: 'sso_login' }).error(error);
    if (prompt === 'none') {
      window.location.reload();
    }
  });
}