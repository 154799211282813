import React from 'react';
import PropTypes from 'prop-types';

import importedLogo from '../../assets/img/icons/logo.svg';

const SplashScreen = ({ t }) => (
  <div className="splash-screen-container">
    <div className="d-flex align-items-center">
      <img src={importedLogo} alt="Main logo icon." className="img-logo "/>
      <p className="main-title mb-0 mt-0">Asystent</p>
    </div>
  </div>
);

export default (SplashScreen);

SplashScreen.propTypes = {
  t: PropTypes.func,
};
