import React from 'react';
import { Row, Col } from 'reactstrap';

import importedPhotoEx1 from '../../assets/img/photos/ex_photo_1.jpg';
import importedIconHeartRed from '../../assets/img/icons/icon_favorites2.svg';

export default function BoxWithImgTwoTitles(props) {
  return (
    <Row className="content-row">
      <Col>
        <Row className="box-share">
          <Col className="tip-photo">
            <img src={importedPhotoEx1} alt="context-menu" className="img-icon-48"/>
            <img src={importedIconHeartRed} alt="context-menu" className="img-icon-16 img-favourite"/>
          </Col>
          <Col className="tip-text-container">
            <Row>
              <p className="tip-title font-medium">10 wskazówek, które pomog Ci zaranżować przytulny salon...</p>
            </Row>
            <Row>
              <p className="tip-desc">10 wskazówek, które pomog Ci zaranżować...</p>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}