import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Map } from 'immutable';

import CatalogBox from '../components/catalogBox';
import pdfLogo from '../../assets/img/icons/PDF_file_icon.svg';
import htmlLogo from '../../assets/img/icons/HTML5_logo_and_wordmark.svg';
import txtLogo from '../../assets/img/icons/Icon-txt.svg';
import docLogo from '../../assets/img/icons/docx_icon.svg';
import xlsLogo from '../../assets/img/icons/xlsx_icon.svg';
import fileLogo from '../../assets/img/icons/GHS-pictogram-unknown.svg';

export function renderCatalogs(list, isShowAllCatalogs, matchUrl, extraCssClass) {
  let firstImgPath;
  list = list.toJS();
  let countFourFirst = (isShowAllCatalogs || list.size <= 4) ? list.size : 4;
  return Object.entries(list).map((item, id) => {
    if (--countFourFirst < 0) return null;
    firstImgPath="";

    if (!item[1].subCatalogs && item[1].files.length > 0) {
      firstImgPath = item[1].files[0].source;
    }
    else if (item[1].subCatalogs && Object.values(item[1].subCatalogs).length>0 && Object.values(item[1].subCatalogs)[0].files.length > 0) {
      firstImgPath = Object.values(item[1].subCatalogs)[0].files[0].source;
    }

    return (
      <CatalogBox
        title={item[1].catalogName}
        linkPathName={`${matchUrl}/${item[0]}`}
        isImgOfCatalog={true}
        imgPath={firstImgPath}
        key={uuidv4()}
        nameAlign="text-center"
        index={id}
        extraCssClass={extraCssClass}
        fileInfo={!item[1].subCatalogs && item[1].files.length ? item[1].files[0].fileInfo : null}/>
    );
  }
  );
}

export function predefinedImgSourceFromType(fileInfo) {

  if (fileInfo) {
    let type;
    if (Map.isMap(fileInfo)) {
      type = fileInfo.get('type');
    }
    else {
      type = fileInfo.type;
    }
    switch (type) {
    case 'application/pdf':
      return pdfLogo;
    case 'text/html':
      return htmlLogo;
    case 'text/plain':
      return txtLogo;
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return docLogo;
    case 'application/vnd.ms-excel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return xlsLogo;
    default: return fileLogo;
    }
  }
  else {
    return fileLogo;
  }
}
