import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';

import Callback from './callback';
import LogoutCallback from './logoutCallback';
import { loginCallbackPath, logoutCallbackPath } from '../utils/AuthConfigProvider';
import NotFound from '../components/notFound';
import Loading from '../components/loading';

import TopBar from '../../voxbox-asystent-ui/containers/topbar';
import Main from '../../voxbox-asystent-ui/containers/main';
import SplashScreen from '../../voxbox-asystent-ui/containers/splashScreen';
import '../../voxbox-asystent-ui/styles/index.scss';


@connect(
  state => ({
    loginStore: state.login.get('loginData'),
    loginFinishingInProgress: state.login.get('login_finishing_in_progress'),
  })
)
export default class Root extends Component {

  state = {
    isLoading: true
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        isLoading: false,
      });
    }, 1000);
  }

  renderContent = () => {
    if (this.props.loginFinishingInProgress) {
      //login finishing is in progress
      return <Loading className="text-center mt-5" />;
    }

    if (!this.props.loginStore) {
      //User not logged in
      return (
        <Switch>

          <Route path={ loginCallbackPath } exact component={Callback} />
          <Route path={ logoutCallbackPath } exact component={LogoutCallback} />
          <Route render={props => (
            <React.Fragment>
              <TopBar {...props}/>
              <Main {...props}/>
            </React.Fragment>
          )}/>
        </Switch>
      );
    }
    else {
      //User logged in
      return (
        <Switch>
          <Route strict path='/' render={props => (
            <React.Fragment>
              <TopBar {...props}/>
              <Main {...props}/>
            </React.Fragment>
          )}/>
          <Route component={NotFound} />
        </Switch>
      );
    }
  };

  render() {
    if (this.state.isLoading) {
      return <SplashScreen/>;
    }
    else {
      return (
        <Container className="main-content mb-2">
          {this.renderContent()}
        </Container>
      );
    }
  }
}

Root.propTypes = {
  loginStore: PropTypes.object, //HOC
  loginFinishingInProgress: PropTypes.bool, //HOC
};
