import { ADD_DIRECTORY, ADD_SUB_FILE, APPLY_PATCH, CLEAR_STORE } from '.';

export function addDirectory({ directory, filter, apiPath }) {
  return {
    type: ADD_DIRECTORY,
    payload: { directory, filter, apiPath }
  };
}

export function addSubFile({ file, apiPath, filter }) {
  return {
    type: ADD_SUB_FILE,
    payload: { file, apiPath, filter }
  };
}

export function clearStore() {
  return {
    type: CLEAR_STORE,
    payload: { }
  };
}

export function applyPatch({ path, patch }) {
  return {
    type: APPLY_PATCH,
    payload: { path, patch }
  };
}