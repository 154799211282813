import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import PropTypes from 'prop-types';

import importedIconMenu from '../../assets/img/icons/icon_menu.svg';

export default function CheckBoxBtnVox(props) {
  let cssClassName = "checkbox-conatiner-vox indentation-in-select-elems";
  if (props.isAddingContextMenu !== undefined) {
    cssClassName += " d-flex align-items-center justify-content-between new-select-box";
  }

  return (
    <FormGroup check className={cssClassName}>
      <Label check>
        <Input type="checkbox" name={props.name} checked={props.isSelected} onChange={props.onCheckboxChange}/>
        <p className="checkbox-icon-vox"></p>
        <p className="text-radio-or-check">{props.text}</p>
      </Label>
      {addContextMenu(props)}
    </FormGroup>
  );
}

function addContextMenu(props) {
  if (props.isAddingContextMenu !== undefined) {
    return <img src={importedIconMenu} alt="context-menu" className="img-icon"/>;
  }
}

CheckBoxBtnVox.propTypes = {
  name: PropTypes.string,
  isAddingContextMenu: PropTypes.bool,
  isSelected: PropTypes.bool,
  text: PropTypes.string,
  onCheckboxChange: PropTypes.func,
};