import { 
  ADD_AUDIT, 
  ADD_AUDIT_ITEM, 
} from '.';

export function addAudit({ audit, apiPath, filter }) {
  return {
    type: ADD_AUDIT,
    payload: { audit, apiPath, filter }
  };
}

export function addAuditItem({ item, apiPath, filter }) {
  return {
    type: ADD_AUDIT_ITEM,
    payload: { item, apiPath, filter }
  };
}