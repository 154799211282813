import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import importedIconBack from '../../assets/img/icons/icon_back.svg';
import importedIconClose from '../../assets/img/icons/icon_close.svg';
import importedIconProfile from '../../assets/img/icons/icon_profile.svg';
import importedIconProfile_active from '../../assets/img/icons/icon_profile_active.svg';
import importedIconLogo from '../../assets/img/icons/logo.svg';

@withTranslation()
@withRouter
@connect(
  state => ({
    loginStore: state.login.get('loginData')
  }),
)
export default class TopBar extends Component {

  onClickedLeftImg = () => {
    this.props.history.goBack();
  };

  setLeftElement = (siteName) => {
    if ([ "/tips" ].includes(siteName) || siteName.includes("inspiration") || siteName.includes("products")
            || siteName.includes("documents")) {
      return <img src={importedIconBack} alt="Icon back." className="img-icon cursor" onClick={this.onClickedLeftImg}/>;
    }
    else if ([ "/createnew", "/profile" ].includes(siteName)) {
      return <img src={importedIconClose} alt="Icon close" className="img-icon cursor" onClick={this.onClickedLeftImg}/>;
    }
    return null;
  };

  createTextElement = (text) => {
    return <p className="d-flex align-items-center font-medium">{this.props.t(text)}</p>;
  };

  setCenterElement = (siteName) => {
    if (siteName === "/profile") {
      return this.createTextElement('profile.title');
    }
    else if (siteName === "/" || siteName === "/tips" ||siteName === "/createnew") {
      return <img src={importedIconLogo} alt="Main logo icon." className="img-logo "/>;
    }
    else {
      const regexUrlProjWithID = RegExp('/project/((?!/).)+');
      return <Link to={regexUrlProjWithID.exec(siteName)[0]}>
        <img src={importedIconLogo} alt="Main logo icon." className="img-logo "/>
      </Link>;
    }
  };

  setRightElement = (siteName) => {
    if (siteName === "/profile") {
      return <img src={importedIconProfile_active} alt="Icon profile." className="img-icon cursor"/>;
    }
    return <Link to="/profile">
      <img src={importedIconProfile} alt="Icon profile." className="img-icon cursor"/>
    </Link>;
  };

  render() {
    let pathName= this.props.location.pathname;
    if (pathName === "/createnew" || pathName.includes("add") || pathName.includes("edit")) {
      return null;
    }
    return (
      <Row className="topbar">
        <Col className="d-flex align-items-center justify-content-start">
          {this.setLeftElement(this.props.location.pathname)}
        </Col>
        <Col className="d-flex align-items-center justify-content-center">
          {this.setCenterElement(this.props.location.pathname)}
        </Col>
        <Col className="d-flex align-items-center justify-content-end">
          {this.props.loginStore ? this.setRightElement(this.props.location.pathname) : null}
        </Col>
      </Row>
    );
  }
}

TopBar.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  t: PropTypes.func,
  loginStore: PropTypes.object
};