import { LOAD_FILE_CONTENT, CREATE_FILE } from '.';

export function loadFileContent({ path, contentType = null, responseType = null }) {
  return {
    type: LOAD_FILE_CONTENT,
    payload: { path, contentType, responseType }
  };
}

export function createFile({ path, file }){
  return {
    type: CREATE_FILE,
    payload: { path, file }
  };
}