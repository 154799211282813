import { REGISTER_MAPPING, PATCH_JSON } from '.';

export function registerMapping({ name, path }) {
  return {
    type: REGISTER_MAPPING,
    payload: { name, path }
  };
}

export function patchJSON({ path, patch }) {
  return {
    type: PATCH_JSON,
    payload: { path, patch }
  };
}

export function patchMappedJSON({ name, patch }) {
  return {
    type: PATCH_JSON,
    payload: { name, patch }
  };
}