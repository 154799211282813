import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Route, Switch, matchPath } from 'react-router-dom';

import Inspiration from './inspiration';
import Products from './products';
import Budget from './budget';
import Documents from './documents';
import EventCalendar from './eventCalendar';
import RowItemWithMenu from '../components/rowItemWithMenu';
import TitleRowWithTwoSideIcon from '../components/titleRowWithTwoSideIcon';
import BoxWithImgTwoTitles from '../components/boxWithImgTwoTitles';
import ProblemWithProjectConfig from '../components/problemWithProjectConfig';
import BudgetGeneralCostBar from '../components/budgetGeneralCostBar';
import { renderCatalogs } from '../utils/projectFunc';
import { LAST_PROJECT_ID_LS_KEY } from '../../voxbox-asystent-ui/utils/constants';
import { bindActionCreators } from 'redux';
import IBIS_actions from 'ibis-ui/ibis-client-middleware/interface';
import { exists } from 'ibis-ui/ibis-client-middleware/ibis-client-functions';

@withTranslation()
@connect(
  state => ({
    projectData: state.IBIS.cache.get('currentProject'),
  }),
  dispatch => {
    return bindActionCreators({
      loadFileContent: IBIS_actions.loadFileContent,
      registerMapping: IBIS_actions.registerMapping,
      createSpace: IBIS_actions.createSpace,
      createPackage: IBIS_actions.createPackage,
      createFile: IBIS_actions.createFile,
      patchJSON: IBIS_actions.patchJSON,
    }, dispatch);
  }
)
export default class Project extends Component {

  componentDidMount() {
    const matchProjectId = matchPath(this.props.location.pathname, { path: '/project/:projectId' });
    const projectId = matchProjectId.params.projectId;

    if (this.props.projectData && (this.props.projectData.get("projectId")===projectId )) {
      localStorage.setItem(LAST_PROJECT_ID_LS_KEY, projectId);
    }
    else {
      this.getProjectFromStorage(projectId);
    }
  }

  getProjectFromStorage = (projectId) => {
    const path = projectId + "/project/" + projectId + "/only/project.json";

    exists(path).then(doesItExist => {
      if (doesItExist) {
        this.prepareMapping(path);
        localStorage.setItem(LAST_PROJECT_ID_LS_KEY, projectId);
      }
    });
  };

  prepareMapping(path) {
    this.props.loadFileContent({ path: path }).then(() => {
      this.props.registerMapping({ path: path, name: "currentProject" });
    });
  }

  getPercentOfValues = (supposed, real) => {
    let value = (real / supposed) * 100;
    return (Number.isNaN(value) ? 0 : parseInt(value, 10)) + "%";
  };

  showBudgetElementsList = () => {
    return this.props.projectData.get("renovationWorks") && this.props.projectData.get("renovationWorks").map((item, key) => {
      let redFont = (item.get("priceReal") > item.get("priceSupposed")) ? "font-red" : "";
      return (
        <Row className="d-flex align-items-between" key={key}>
          <Col className="d-flex flex-fill list-elem-name"><p>{item.get("value")}</p></Col>
          <Col className={"d-flex flex-fill list-elem-cost " + redFont}>
            {item.get("priceSupposed") + " / " + item.get("priceReal")}
          </Col>
          <Col className={"list-elem-percent " + redFont}>
            {this.getPercentOfValues(item.get("priceSupposed"), +item.get("priceReal"))}
          </Col>
        </Row>
      );
    }).toSetSeq();
  };

  goToNextView = (siteTo) => {
    this.props.history.push(`${this.props.match.url}/${siteTo}`);
  };

  renderContent = () => {
    const { t } = this.props;
    return (
      <Row className="project">
        <Col>
          <RowItemWithMenu text={this.props.projectData.get("name")} type="text"
            extraCssClass="project-name"/>
          <Row className="box-share budget budget-widget">
            <Col>
              <TitleRowWithTwoSideIcon title={t('project.budget')} iconRight="next"
                onClickFunRight={() => this.goToNextView("budget")}/>
              <BudgetGeneralCostBar list={this.props.projectData.get("renovationWorks")} t={t}
                extraCssClass="mt-0"/>
              <Row className="d-flex title-group">
                <Col className="d-flex flex-fill font-medium list-elem-name">{t('project.category')}</Col>
                <Col className="d-flex flex-fill font-medium list-elem-cost">{t('project.budget')}</Col>
                <Col className="font-medium list-elem-percent">{t('project.procent')}</Col>
              </Row>
              {this.showBudgetElementsList()}
            </Col>
          </Row>
          <Row className="box-share">
            <Col>
              <TitleRowWithTwoSideIcon title={t('project.documents')} iconRight="next"
                onClickFunRight={() => this.goToNextView("documents")}/>
              <Row>
                {renderCatalogs(this.props.projectData.getIn([ "database", "documents" ]), false,
                  this.props.match.url+"/documents")}
              </Row>
            </Col>
          </Row>
          <Row className="box-share">
            <Col>
              <TitleRowWithTwoSideIcon title={t('project.products')} iconRight="next"
                onClickFunRight={() => this.goToNextView("products")}/>
              <Row>
                {renderCatalogs(this.props.projectData.getIn([ "database", "products" ]), false,
                  this.props.match.url+"/products")}
              </Row>
            </Col>
          </Row>
          <Row className="box-share">
            <Col>
              <TitleRowWithTwoSideIcon title={t('project.inspiration')} iconRight="next"
                onClickFunRight={() => this.goToNextView("inspiration")}/>
              <Row>
                {renderCatalogs(this.props.projectData.getIn([ "database", "inspiration" ]), false,
                  this.props.match.url+"/inspiration")}
              </Row>
            </Col>
          </Row>
          <Row className="box-share">
            <Col>
              <TitleRowWithTwoSideIcon title={t('project.tips')} iconRight="next"/>
              <BoxWithImgTwoTitles/>
              <BoxWithImgTwoTitles/>
              <BoxWithImgTwoTitles/>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };


  render() {
    if (this.props.projectData === undefined || !this.props.projectData || !this.props.projectData.get("isCompleteProject")) {
      return <ProblemWithProjectConfig/>;
    }
    return (
      <Switch>
        <Route exact path={this.props.match.path}>
          {this.renderContent()}
        </Route>
        <Route path={`${this.props.match.path}/budget`} component={Budget}/>
        <Route path={`${this.props.match.path}/inspiration`} component={Inspiration}/>
        <Route path={`${this.props.match.path}/products`} component={Products}/>
        <Route path={`${this.props.match.path}/documents`} component={Documents}/>
        <Route path={`${this.props.match.path}/calendar`} component={EventCalendar} />
      </Switch>
    );
  }
}

Project.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
  projectData: PropTypes.object,
  stateData: PropTypes.object,
  t: PropTypes.func, //HOC
  createTestProject: PropTypes.func,
  createSpace: PropTypes.func,
  createPackage: PropTypes.func,
  createFile: PropTypes.func,
  loadFileContent: PropTypes.func,
  registerMapping: PropTypes.func,
};
