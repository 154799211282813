import * as notify from '../../main/utils/notify';
import i18n from '../../main/utils/i18nConfigProvider';
import { clearOwnLS } from '../../main/utils/localStorageProvider';

export const namespace = '@OIDC/';

export const LOG_IN = namespace + 'LOG_IN';
export const LOG_IN_FINISH = namespace + 'LOG_IN_FINISH';
export const LOG_IN_SUCCESS = namespace + 'LOG_IN_SUCCESS';
export const LOG_IN_FAIL = namespace + 'LOG_IN_FAIL';
export const LOG_OUT = namespace + 'LOG_OUT';
export const FORCED_LOG_OUT = namespace + 'FORCED_LOG_OUT';

export function logIn(prompt = 'login') {
  return {
    type: LOG_IN,
    payload: { prompt }
  };
}

export function logInFinish({ data }) {
  return {
    type: LOG_IN_FINISH,
    payload: { data }
  };
}

export function logInSuccess({ data }) {
  return {
    type: LOG_IN_SUCCESS,
    payload: { data }
  };
}

export function logInFail() {
  return {
    type: LOG_IN_FAIL
  };
}

export function logOut() {
  clearOwnLS();
  return {
    type: LOG_OUT,
    payload: {}
  };
}

export function forcedLogOut() {
  notify.warning(i18n.t('common:forced_logout_notification_title'), i18n.t('common:forced_logout_notification_body'));
  return {
    type: FORCED_LOG_OUT,
    payload: {}
  };
}
