import * as fileActions from './file';
import * as auditActions from './audit';
import * as assignmentActions from './assignment';
import * as directoryActions from './content';
import * as attributeActions from './attribute';
import * as JSONPatchActions from './JSONPatch';

export const namespace = '@IBIS/';

export const LOAD_ATTRIBUTES = namespace + 'LOAD_ATTRIBUTES';
export const LOAD_MORE_AUDITS = namespace + 'LOAD_MORE_AUDITS';
export const LOAD_MORE_ASSIGNMENTS = namespace + 'LOAD_MORE_ASSIGNMENTS';
export const LOAD_MORE_TRANSITIONS = namespace + 'LOAD_MORE_TRANSITIONS';
export const CREATE_SPACE = namespace + 'CREATE_SPACE';
export const LOAD_FILE_CONTENT = namespace + 'LOAD_FILE_CONTENT';
export const LOAD_MORE = namespace + 'LOAD_MORE';
export const CREATE_PACKAGE = namespace + 'CREATE_PACKAGE';
export const CREATE_FILE = namespace + 'CREATE_FILE';
export const REGISTER_MAPPING = namespace + 'REGISTER_MAPPING';
export const PATCH_JSON = namespace + 'PATCH_JSON';
export const DELETE = namespace + 'DELETE';

const actions = {
  ...fileActions,
  ...auditActions,
  ...assignmentActions,
  ...directoryActions,
  ...attributeActions,
  ...JSONPatchActions
};
export default actions;
