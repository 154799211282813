import * as axios from 'axios';

import { __env } from '../../envloader';
import { getLS, setLS } from './localStorageProvider';
import * as notify from './notify';
import i18n from './i18nConfigProvider';

//Paths of login and logout callback to use where needed
export const loginCallbackPath = '/ui/auth/login-callback/';
export const logoutCallbackPath = '/ui/auth/logout-callback/';

//if config is missing it will be pulled from well-known or localstorage
export function getAuthConfig() {
  let promise;
  const config = JSON.parse(getLS('endpoint_config'));
  if (!config || config.length === 0) {
    promise = new Promise((resolve, reject) => {
      axios.get(__env.AUTH_OPENID_PROVIDER + '.well-known/openid-configuration').then(function (response) {
        setLS('endpoint_config', JSON.stringify(response.data));
        resolve(updateConfigVariables());
      }).catch(function (error) {
        notify.error(i18n.t('common:authentication_error_notification_title'), error.message);
        setLS('sso_down', true);
        reject(error);
      });
    });
  }
  else {
    promise = new Promise((resolve) => {
      resolve(updateConfigVariables());
    });
  }
  return promise;
}

export function checkIfLoginIsPossible() {
  if (!__env.AUTH_OPENID_PROVIDER || 0 === __env.AUTH_OPENID_PROVIDER.length) {
    return false;
  }
  return true;
}

export function getProfileRedirect() {
  return new Promise(resolve => {
    if (__env.AUTH_PROFILE_REDIRECT === 'none')
      resolve(null);
    else if (__env.AUTH_PROFILE_REDIRECT)
      resolve(__env.AUTH_PROFILE_REDIRECT);
    else axios.get(__env.AUTH_OPENID_PROVIDER)
      .then(response => {
        resolve(response.data['account-service']);
      })
      .catch(() => {
        resolve(null);
      });
  });
}

//this function retrieves needed variables from local storage
function updateConfigVariables() {
  let result = {};
  const config = JSON.parse(getLS('endpoint_config'));
  result.AUTH_AUTHORIZATION_ENDPOINT = config.authorization_endpoint;
  result.AUTH_TOKEN_ENDPOINT = config.token_endpoint;
  result.AUTH_ISSUER = config.issuer;
  result.AUTH_JWKS_URI = config.jwks_uri;
  result.AUTH_CHECK_SESSION_IFRAME = config.check_session_iframe;
  result.AUTH_END_SESSION_ENDPOINT = config.end_session_endpoint;
  return result;
}
