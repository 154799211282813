import { fromJS, Map } from 'immutable';

import { LOAD_PLACE_STATE_LIST, LOAD_LIST_OF_ROOMS, LOAD_LIST_OF_RENOVATION_WORKS }
  from '../actions/createProjectDefaultDataActions';

export const createProjectDefaultDataReducer = (defaultState = null, action) => {
  switch (action.type) {
  case LOAD_PLACE_STATE_LIST:
    if (defaultState) {
      return defaultState;
    }
    else {
      defaultState = Map();
      return defaultState.set("placeState", fromJS(action.payload));
    }

  case LOAD_LIST_OF_ROOMS:
    return defaultState.set("rooms", fromJS(action.payload));

  case LOAD_LIST_OF_RENOVATION_WORKS:
    return defaultState.set("renovationWorks", fromJS(action.payload));

  default:
    return defaultState;
  }
};