import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import PropTypes from 'prop-types';

const ModalConfirmation = ({ bodyModal, headerModal, onClose, onConfirm, onDecline, noText, yesText }) => {

  const onEnable = () => {
    onConfirm();
    onClose();
  };
  const onDisable = () => {
    onDecline();
    onClose();
  };

  return (
    <Modal isOpen centered>
      <ModalHeader>
        <p className="font-medium">{headerModal}</p>
      </ModalHeader>
      <ModalBody>
        {bodyModal}
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={onEnable}>{yesText}</Button>
        <Button color="secondary" onClick={onDisable}>{noText}</Button>
      </ModalFooter>
    </Modal>
  );
};
export default ModalConfirmation;

ModalConfirmation.propTypes = {
  bodyModal: PropTypes.node,
  headerModal: PropTypes.string,
  noText: PropTypes.string,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  onDecline: PropTypes.func,
  yesText: PropTypes.string,
  singleButton: PropTypes.bool
};