import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import importedIconMenu from '../../assets/img/icons/icon_menu.svg';

export default function LinkTextIcon({ extraCssClass=null, isNoIcon=false, to, text }) {
  let cssClassName = "d-flex font-medium mt-20 cursor "+ extraCssClass;
  cssClassName += " justify-content-"+((isNoIcon) ? "center ": "between ");

  return (
    <Link to={to} className={cssClassName}>
      <p>{text}</p>
      {(isNoIcon) ? null : <img src={importedIconMenu} alt="action-text" className="img-icon"/>}
    </Link>
  );
}

LinkTextIcon.propTypes = {
  extraCssClass: PropTypes.string,
  to: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]),
  text: PropTypes.string,
  isNoIcon: PropTypes.bool,
};