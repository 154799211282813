import actions from './actions';
import {
  LOAD_ATTRIBUTES,
  LOAD_MORE_AUDITS,
  LOAD_FILE_CONTENT,
  CREATE_SPACE,
  LOAD_MORE,
  LOAD_MORE_ASSIGNMENTS,
  CREATE_PACKAGE,
  CREATE_FILE,
  PATCH_JSON,
  REGISTER_MAPPING,
  DELETE
} from './interface/index';
import * as notify from '../main/utils/notify';
import i18n from '../main/utils/i18nConfigProvider';
import qs from 'qs';
import axios from 'axios';
import { createIBISClient, 
  loadMore, loadMoreAudits, 
  loadMoreAssignments, 
  loadFileContent, 
  createSpace, 
  createPackage, 
  createFile,
  getAttributes,
  _delete,
  patchIBISfile
} from './ibis-client-functions';

function handleBackendErrors(error) {
  if (error.status === 403) {
    notify.error(i18n.t('ibis-ui:ibis_backend_error__notification_title'), i18n.t('ibis-ui:permission_denied'));
  }
  else {
    notify.error(i18n.t('ibis-ui:ibis_backend_error__notification_title'), error.message);
  }
}

export const getIbisMiddleware = (axiosInstance) => {
  if (!axiosInstance) {
    axiosInstance = axios.create();
  }

  let name;

  createIBISClient(axiosInstance);

  return store => next => action => {
    switch (action.type) {
    case LOAD_MORE:
      return loadMore(action.payload.path, action.payload.filterContext, action.payload.filter, action.payload.cursor, action.payload.fetchAll)
        .then(directory => {
          if (directory.more)
            directory.more = qs.parse(directory.more, { ignoreQueryPrefix: true });
          store.dispatch(actions.addDirectory({ directory: directory, filter: action.payload.filter, apiPath: action.payload.path }));
          directory.items.map(file => {
            store.dispatch(actions.addSubFile({ file: file, apiPath: action.payload.path, filter: action.payload.filter }));
            return null;
          });
        }).catch(function (error) {
          store.dispatch(actions.addDirectory({ directory: { more: null }, apiPath: action.payload.path, filter: action.payload.filter }));
          handleBackendErrors(error);
        });

    case LOAD_MORE_AUDITS:
      return loadMoreAudits(action.payload.path, action.payload.filter, action.payload.cursor, action.payload.fetchAll).then(audit => {
        if (audit.more)
          audit.more = qs.parse(audit.more, { ignoreQueryPrefix: true });
        store.dispatch(actions.addAudit({ audit: audit, apiPath: action.payload.path, filter: action.payload.filter }));
        audit.items.map(auditItem => {
          store.dispatch(actions.addAuditItem({ item: auditItem, apiPath: action.payload.path, filter: action.payload.filter }));
          return null;
        });
      }).catch(function (error) {
        store.dispatch(actions.addAudit({ audit: { more: null }, apiPath: action.payload.path, filter: action.payload.filter }));
        handleBackendErrors(error);
      });


    case LOAD_MORE_ASSIGNMENTS:
      return loadMoreAssignments(action.payload.path, action.payload.cursor, action.payload.fetchAll).then(assignments => {
        if (action.payload.returnData)
          return assignments;
        if (assignments.more)
          assignments.more = qs.parse(assignments.more, { ignoreQueryPrefix: true });
        store.dispatch(actions.addAssignments({ assignments: assignments, apiPath: action.payload.path }));
        assignments.items.map(assignment => {
          store.dispatch(actions.addAssignment({ assignment: assignment, apiPath: action.payload.path }));
          return null;
        });
      }).catch(function (error) {
        store.dispatch(actions.addAssignments({ assignments: { more: null }, apiPath: action.payload.path }));
        handleBackendErrors(error);
      });

    case LOAD_FILE_CONTENT:
      let contentType;
      name = action.payload.path.substring(action.payload.path.lastIndexOf('/') + 1);
      if (action.payload.contentType) {
        contentType = action.payload.contentType;
      }
      else {
        const fileData = store.getState().IBIS.content.getIn([ 
          action.payload.path.substring(0, action.payload.path.lastIndexOf('/') + 1), 'items', null, name ]);
        if (fileData) {
          contentType = fileData.toJS().content_type;
        }
      }
      return loadFileContent(action.payload.path, contentType, action.payload.responseType).then(content => {
        store.dispatch(actions.addFileContent({ path: action.payload.path, content: content }));
      }).catch(function (error) {
        handleBackendErrors(error);
      });

    case CREATE_SPACE:
      return createSpace(action.payload.name, action.payload.policy).then(space => {
        store.dispatch(actions.addSubFile({ file: space, apiPath: '', filter: space.attributes.policy }));
        store.dispatch(actions.addSubFile({ file: space, apiPath: '', filter: null }));
        store.dispatch(actions.addDirectory({ directory: space, apiPath: space.apiPath }));
      });

    case CREATE_PACKAGE:
      return createPackage(action.payload.spaceName, action.payload.category, action.payload.subcategory, action.payload.identifier, action.payload.version)
        .then(_package => {
          let category = {}, identifier = {}, version = {};

          category.name = _package.attributes.category;
          category.attributes = {};
          category.attributes.category = category.name;
          category.attributes.entity = 'package-category';
          category.type = _package.type;
          store.dispatch(actions.addSubFile({ file: category, apiPath: action.payload.spaceName + '/', filter: null }));
          store.dispatch(actions.addDirectory({ directory: category, apiPath: action.payload.spaceName + '/' + category.name + '/' }));

          identifier.name = _package.name.split('/')[1];
          identifier.attributes = {};
          identifier.attributes.category = _package.attributes.category;
          identifier.attributes.entity = 'package-line';
          identifier.type = _package.type;
          store.dispatch(actions.addSubFile({ file: identifier, apiPath: action.payload.spaceName + '/' + _package.attributes.category + '/', filter: null }));
          store.dispatch(actions.addDirectory({
            directory: identifier, apiPath: action.payload.spaceName + '/' + _package.attributes.category + '/' + identifier.name + '/'
          }));

          version.name = _package.name.split('/')[2];
          version.attributes = _package.attributes;
          version.type = _package.type;
          //IBIS-1118 No idea what state the package will have
          store.dispatch(actions.addSubFile({
            file: version, apiPath: action.payload.spaceName + '/' + _package.attributes.category + '/' + _package.name.split('/')[1] + '/',
            filter: null
          }));
          store.dispatch(actions.addDirectory({
            directory: identifier,
            apiPath: action.payload.spaceName + '/' + _package.attributes.category + '/' + _package.name.split('/')[1] + '/' + version.name + '/'
          }));

        });

    case CREATE_FILE:
      return createFile(action.payload.path, action.payload.file).then(file => {
        store.dispatch(actions.addSubFile({ file: file, apiPath: action.payload.path.substring(0, action.payload.path.lastIndexOf('/') + 1), filter: null }));
      });

    case LOAD_ATTRIBUTES:
      return getAttributes()
        .then(attributes => {
          attributes.map(attribute => {
            return store.dispatch(actions.setAttribute({ attribute: attribute }));
          });
        }).catch(function (error) {
          handleBackendErrors(error);
        });

    case PATCH_JSON:
      let path;
      if (action.payload.path){
        name = store.getState().IBIS.mapping.getIn([ 'pathToName', action.payload.path ]);
        path = action.payload.path;
      }
      else {
        path = store.getState().IBIS.mapping.getIn([ 'nameToPath', action.payload.name ]);
        name = action.payload.name;
      }
      store.dispatch(actions.applyPatch({ path: path, patch: action.payload.patch }));
      store.dispatch(actions.updateCache({
        name: name,
        value: store.getState().IBIS.content.getIn([ path ])
      }));
      patchIBISfile(path, store.getState().IBIS.content.getIn([ path ]).toJS());
      break;

    case REGISTER_MAPPING:
      store.dispatch(actions.setMapping({ name: action.payload.name, path: action.payload.path }));
      store.dispatch(actions.updateCache({
        name: action.payload.name,
        value: store.getState().IBIS.content.getIn([ action.payload.path ])
      }));
      break;

    case DELETE:
      _delete(action.payload.path).then(() => {
        store.dispatch(actions.clearStore());
      });
      break;

    default:
      break;
    }

    next(action);
  };
};
