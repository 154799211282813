import React from 'react';
import { Container } from 'reactstrap';

import importedLoading from '../../assets/img/icons/loading_test.svg';

export default function Loading(props) {
  return (
    <Container className="loading-container dashboard-cover">
      <img id="loading" src={importedLoading} alt="loading-icon"/>
    </Container>
  );
}




