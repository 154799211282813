import React from 'react';
import PropTypes from 'prop-types';

export default function EmptyFieldMessageElement(props) {
  let cssClassName = "font-medium mtb-20 ";
  if (props.extraCssClass !== undefined) {
    cssClassName += " "+ props.extraCssClass;
  }
  return (
    <p className={cssClassName}>{props.msg}</p>
  );
}

EmptyFieldMessageElement.propTypes = {
  extraCssClass: PropTypes.string,
  msg: PropTypes.string,
};