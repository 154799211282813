import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import TitleRow from '../components/titleRow';
import BarSearchMoreAdd from '../components/barSearchMoreAdd';
import { renderCatalogs } from '../utils/projectFunc';
import DocumentBoard from './documentBoard';
import { Map } from 'immutable';
import { bindActionCreators } from 'redux';
import { addNewCatalogToDatabase } from '../actions/projectActions';
import ModalAddNewCatalog from '../components/modalNewCatalog';

@withTranslation()
@connect(
  state => ({
    projectData: state.IBIS.cache.get('currentProject')
  }),
  dispatch => {
    return bindActionCreators({  addNewCatalogToDatabase }, dispatch);
  }
)
export default class Documents extends Component {
  state = {
    isShowModalAddNewCatalog: false,
  };

  modalAddNewShowHide = () => {
    this.setState((prevState) => ({
      isShowModalAddNewCatalog: !prevState.isShowModalAddNewCatalog
    }));
  };

  addNewCatalog = (newName) => {
    this.props.addNewCatalogToDatabase(Map({
      projectId: this.props.projectData.get("projectId"),
      group: "documents",
      newCatalog: newName
    }));
    this.modalAddNewShowHide();
  };

  renderContent = () => {
    const { t } = this.props;
    return (
      <Row className="documents">
        <Col>
          <TitleRow title={t('project.documents')} extraCssClass="main-title"/>
          <BarSearchMoreAdd onClickAdd={() => this.modalAddNewShowHide()}/>
          <Row className="my-3">
            <p>{t('form.catalogs')}</p>
          </Row>
          <Row className="d-flex flex-wrap align-items-start justify-content-start catalogs-container mt-24">
            {renderCatalogs(this.props.projectData.getIn([ "database", "documents" ]),
              true, this.props.match.url, "mb-4")}
          </Row>
        </Col>
        {this.state.isShowModalAddNewCatalog ?
          <ModalAddNewCatalog
            onCancel={() => this.modalAddNewShowHide()}
            onSave={(newName)=>this.addNewCatalog(newName)}/>
          : null
        }
      </Row>
    );
  };

  render() {
    return (
      <Switch>
        <Route exact path={this.props.match.path}>
          {this.renderContent}
        </Route>
        <Route path={`${this.props.match.path}/:catalog`} component={DocumentBoard}/>
      </Switch>
    );
  }
}

Documents.propTypes = {
  addNewCatalogToDatabase: PropTypes.func,
  match: PropTypes.object,
  projectData: PropTypes.object,
  t: PropTypes.func
};
