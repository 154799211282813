import { Map } from 'immutable';

import { SET_MAPPING } from '../actions/index';

const defaultState = Map({
  nameToPath: Map({}),
  pathToName: Map({}),
});

export const mappingReducer = (state = defaultState, action) => {
  switch (action.type) {

  case SET_MAPPING:
    return state.setIn([ 'nameToPath', action.payload.name ], action.payload.path)
      .setIn([ 'pathToName', action.payload.path ], action.payload.name);

  default:
    return state;
  }
};
