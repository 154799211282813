import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import * as _ from 'lodash';

import { checkIfLoginIsPossible, getProfileRedirect } from '../../main/utils/AuthConfigProvider';
import { logIn } from '../../oidc-middleware/actions/login';
import Loading from '../components/loading';
import LinkBtn from '../../voxbox-asystent-ui/components/linkBtn';

@withTranslation()
@connect(
  state => ({
    loginStore: state.login.get('loginData'),
    loginFinishingInProgress: state.login.get('login_finishing_in_progress'),
  }),
  dispatch => {
    return bindActionCreators({
      logIn: logIn,
    }, dispatch);
  }
)
export default class Login extends Component {
  state = {
    dropdownOpen: false,
    profileRedirect: null
  };

  componentDidMount() {
    if (checkIfLoginIsPossible())
      getProfileRedirect().then(value => this.setState({ profileRedirect: value }));
  }

  dropdownToggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  };

  renderLoginBar() {
    const throttledLogin = _.throttle(this.props.logIn, 1000);

    if (!checkIfLoginIsPossible()) {
      //Login is impossible
      return null;
    }

    if (this.props.loginFinishingInProgress) {
      //login finishing is in progress
      return <Loading size="sm" />;
    }

    if (!this.props.loginStore) {
      //user is not logged in
      return (
        <LinkBtn
          to="/"
          color="red"
          extraCssClass="m-lr-693 btn-register"
          text={this.props.t('main.register_to_create_project')}
          onClickFun={() => throttledLogin()}/>
      );
    }

    //user is logged in - no login button needed
    return null;
  }

  render() {
    return (
      this.renderLoginBar()
    );
  }
}

Login.propTypes = {
  logIn: PropTypes.func, //HOC
  location: PropTypes.object,
  t: PropTypes.func, //HOC,
  loginStore: PropTypes.object, //HOC
  loginFinishingInProgress: PropTypes.bool, //HOC
};
