import qs from 'qs';

import * as notify from '../../utils/notify';
import { __env } from '../../../envloader/index';
import i18n from '../../utils/i18nConfigProvider';
import { getAuthConfig } from '../../utils/AuthConfigProvider';
import { getGV, setGV } from '../../utils/globalVariablesProvider';

export class AccessTokenProvider {

  _gettingTokenInProgress = null;

  _getAccessToken = (axiosInstance) => {
    const that = this;
    const gettingPromise = new Promise((resolve, reject) => {

      const data = {
        grant_type: 'refresh_token',
        client_id: __env.AUTH_CLIENT_ID,
        refresh_token: getGV('refreshToken'),
      };
      //if AUTH_CLIENT_SECRET is empty, null or undefined it wont be sent
      if (__env.AUTH_CLIENT_SECRET && 0 !== __env.AUTH_CLIENT_SECRET.length) {
        data.client_secret = __env.AUTH_CLIENT_SECRET;
      }
      getAuthConfig().then((config) => {
        axiosInstance.post(config.AUTH_TOKEN_ENDPOINT, qs.stringify(data),
          { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
          .then(function (response) {
            // accessToken refreshed successfully - saving in localStorage
            setGV('accessToken', response.data.access_token);
            setGV('refreshToken', response.data.refresh_token);
            that._gettingTokenInProgress = null;
            resolve(response.data.access_token);
          })
          .catch((error) => {
            if (error.response.status === 400) {
              notify.error(i18n.t('common:token_refresh_error_notification_title'),
                i18n.t('common:token_refresh_error_notification_body'));
            }
            else {
              notify.error(i18n.t('common:access_token_error_notification_title'), error.message);
            }
            that._gettingTokenInProgress = null;
            reject(error);
          });
      });
    });
    this._gettingTokenInProgress = gettingPromise;
    return gettingPromise;
  };

  obtainToken(axiosAuthInstance, force = false) {
    const accessToken = getGV('accessToken');
    if (!force && accessToken) {
      return new Promise((resolve, reject) => {
        // not forcing and accessToken exists in localStorage
        //TODO: Check if accessToken hasn't expired
        resolve(accessToken);
      });
    }
    else {
      // force mode or accessToken does not exist in localStorage - getting accessToken

      if (!this._gettingTokenInProgress) {
        //There is no getting access token in progress
        return this._getAccessToken(axiosAuthInstance);
      }
      else {
        //There is some getting access token in progress
        return this._gettingTokenInProgress;
      }
    }
  }
}
