import React from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';

import TitleRow from './titleRow';
import importedIconNext from '../../assets/img/icons/icon_next.svg';

export default function TitleRowWithTwoSideIcon({ iconLeft, iconRight, onClickFunRight, title }) {
  return (
    <Row>
      {createSideElement(true, iconLeft)}
      <Col>
        <TitleRow title={title}/>
      </Col>
      {createSideElement(false, iconRight, onClickFunRight)}
    </Row>
  );
}

function createSideElement(isLeftSide, action, onClickFun) {
  if (action !== undefined) {
    let cssClass = "d-flex align-items-center justify-content-";
    isLeftSide ? (cssClass += "start") : (cssClass += "end");
    return (
      <Col className={cssClass}>
        <img src={getIconByActionName(action)} alt="context-menu" className="img-icon-16 cursor" onClick={onClickFun}/>
      </Col>
    );
  }
  return (<Col/>);
}

function getIconByActionName(action) {
  switch (action) {
  case 'next':
    return importedIconNext;
  default:
    return null;
  }
}

TitleRowWithTwoSideIcon.propTypes = {
  title: PropTypes.string,
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  onClickFunRight: PropTypes.func
};