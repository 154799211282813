import React, { Component } from 'react';
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

@withTranslation()
export default class ModalAddNewCatalog extends Component {
  state = {
    modalInputNewCatalogName: ""
  };

  updateModalInputNewName = (evt) => {
    this.setState({
      modalInputNewCatalogName: evt.target.value
    });
  };

  render() {
    const { t } = this.props;
    return (
      <Modal isOpen centered autoFocus={false}>
        <ModalHeader>
          <p className="font-medium">{t('form.new_catalog_name')}</p>
        </ModalHeader>
        <ModalBody>
          <Input
            type="text"
            name="title"
            id="title"
            value={this.state.modalInputNewName}
            onChange={this.updateModalInputNewName}
            placeholder={t('form.name_dots')}
            className="font-medium"
            autoFocus={true}/>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={()=>this.props.onSave(this.state.modalInputNewCatalogName)}>{t('form.save')}</Button>
          <Button color="secondary" onClick={()=>this.props.onCancel()}>{t('form.cancel')}</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

ModalAddNewCatalog.propTypes = {
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  t: PropTypes.func
};