import React, { Component } from 'react';
import { Col, Row, Card, CardImg, CardText, CardBody, CardTitle } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import importedPhotoEx1 from '../../assets/img/photos/ex_photo_1.jpg';
import importedPhotoEx2 from '../../assets/img/photos/ex_photo_2.jpg';
import importedPhotoEx3 from '../../assets/img/photos/ex_photo_3.jpg';
import importedPhotoEx4 from '../../assets/img/photos/ex_photo_4.jpg';
import LinkBtn from '../components/linkBtn';
import TitleRowWithTwoSideIcon from '../components/titleRowWithTwoSideIcon';

const items = [
  {
    src: importedPhotoEx1,
    caption: '10 wskazówek, które pomog Ci zaranżować przytulny salon w Twoim ulubionym stylu',
    description: '10 wskazówek, które pomog Ci zaranżować przytulny salon w Twoim ulubionym stylu 10 wskazówek, ' +
            'które pomog Ci zaranżować przytulny salon w Twoim ulubionym stylu',
    altText: 'Slide 1',
    id: "key01"
  },
  {
    src: importedPhotoEx2,
    caption: '10 wskazówek, które pomog Ci zaranżować przytulny salon w Twoim ulubionym stylu',
    description: '10 wskazówek, które pomog Ci zaranżować przytulny salon w Twoim ulubionym stylu 10 wskazówek, ' +
            'które pomog Ci zaranżować przytulny salon w Twoim ulubionym stylu',
    altText: 'Slide 2',
    id: "key02"
  },
  {
    src: importedPhotoEx3,
    caption: '10 wskazówek, które pomog Ci zaranżować przytulny salon w Twoim ulubionym stylu',
    description: '10 wskazówek, które pomog Ci zaranżować przytulny salon w Twoim ulubionym stylu 10 wskazówek, ' +
            'które pomog Ci zaranżować przytulny salon w Twoim ulubionym stylu',
    altText: 'Slide 3',
    id: "key03"
  },
  {
    src: importedPhotoEx4,
    caption: '10 wskazówek, które pomog Ci zaranżować przytulny salon w Twoim ulubionym stylu',
    description: '10 wskazówek, które pomog Ci zaranżować przytulny salon w Twoim ulubionym stylu 10 wskazówek, ' +
            'które pomog Ci zaranżować przytulny salon w Twoim ulubionym stylu',
    altText: 'Slide 4',
    id: "key04"
  }
];

@withTranslation()
export default class Tips extends Component {
  renderSlides = () => {
    return items.map((item) => {
      return (
        <Card className="box-share" key={item.id}>
          <CardImg src={item.src} alt={item.altText}/>
          <CardBody className="tip-text-container">
            <CardTitle className="font-medium">{item.caption}</CardTitle>
            <CardText>{item.description}</CardText>
          </CardBody>
        </Card>
      );
    });
  };

  render() {
    const { t } = this.props;
    return (
      <Row className="tips-mini">
        <Col>
          <TitleRowWithTwoSideIcon title={t('tips.title')}/>
          <Row className="caruzel-horizontal-scrool">
            <div className="d-flex">
              {this.renderSlides()}
            </div>
          </Row>
          <Row>
            <LinkBtn to="/tips"
              color="white"
              text={t('form.see_more')}
              extraCssClass="m-lr-693"
            />
          </Row>
        </Col>
      </Row>
    );
  }
}

Tips.propTypes = {
  t: PropTypes.func
};