import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';

import MainApp from './main/index';
import i18n from './main/utils/i18nConfigProvider';
import { initializeSentry } from './main/utils/logger';
import { __env } from './envloader';
import { getLS, removeLS } from './main/utils/localStorageProvider';
import { logInRedirect } from './oidc-middleware/utils/login-utils';
import { checkIfLoginIsPossible, loginCallbackPath } from './main/utils/AuthConfigProvider';

initializeSentry();

// If url in not login callback, then try to make new silent login
if (!window.location.href.includes(loginCallbackPath))
  removeLS('login_in_progress');

if (
  checkIfLoginIsPossible()
  && __env.AUTH_AUTO_ON_APP_OPEN
  && !getLS('login_in_progress', true)
  && !getLS('logout_in_progress')
  && !getLS('sso_down')
)
  logInRedirect('none');
else {
  removeLS('sso_down');
  ReactDOM.render(
    <I18nextProvider i18n={i18n}><MainApp /></I18nextProvider>,
    document.getElementById('root')
  );
}

// Turn SW off temporally VOX-5262
// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker.register('/service-worker.js').then(function(registration) {
//     // Registration was successful
//     console.log('ServiceWorker registration successful with scope: ', registration.scope);
//   }).catch(function(err) {
//     // registration failed :(
//     	console.log('ServiceWorker registration failed: ', err);
//   });
// }
// else {
//   console.log('ServiceWorker is not supported by this browser - check your https connection also. ' +
//           'In development with Chrome use: chrome://flags/#unsafely-treat-insecure-origin-as-secure ' +
//           'with your domain url including protocol and port.');
// }
