import React from 'react';
import { Row } from 'reactstrap';
import PropTypes from 'prop-types';

import importedIconMenu from '../../assets/img/icons/icon_menu.svg';

export default function RowItemWithMenu(props) {
  let cssClassName = "d-flex justify-content-between font-medium ";
  if (props.extraCssClass !== undefined) {
    cssClassName += " "+ props.extraCssClass;
  }

  return (
    <Row className={cssClassName}>
      <p className="title-element">{props.text}</p>
      <img src={importedIconMenu} alt="context-menu" className="img-icon"/>
    </Row>
  );
}

RowItemWithMenu.propTypes = {
  extraCssClass: PropTypes.string,
  text: PropTypes.string,
};