import { Map, fromJS, OrderedMap } from 'immutable';

import { 
  ADD_DIRECTORY, 
  ADD_SUB_FILE, 
  ADD_AUDIT, 
  ADD_AUDIT_ITEM, 
  ADD_ASSIGNMENTS, 
  ADD_ASSIGNMENT, 
  ADD_FILE_CONTENT, 
  APPLY_PATCH, 
  CLEAR_STORE } from '../actions/index';

const defaultState = Map({
});

export const directoryReducer = (state = defaultState, action) => {
  switch (action.type) {

  case ADD_DIRECTORY:
    let directory = Map({
      name: action.payload.directory.name,
      type: action.payload.directory.type,
      attributes: fromJS(action.payload.directory.attributes),
      more: state.getIn([ action.payload.apiPath, 'more' ], Map({})),
      items: state.getIn([ action.payload.apiPath, 'items' ], Map({})),
      audit: state.getIn([ action.payload.apiPath, 'audit' ], Map({})),
      assignments: state.getIn([ action.payload.apiPath, 'assignments' ], Map({}))
    });

    directory = directory.setIn([ 'more', action.payload.filter ], fromJS(action.payload.directory.more));
    return state.setIn([ action.payload.apiPath ], directory);

  case ADD_SUB_FILE:
    let file;
    if (action.payload.file.type === 'directory') {
      file = Map({
        name: action.payload.file.name,
        type: action.payload.file.type,
        attributes: fromJS(action.payload.file.attributes),
      });
    }
    else {
      file = Map({
        name: action.payload.file.name,
        content_type: action.payload.file.content_type,
        user_content_last_modified: action.payload.file.user_content_last_modified,
        size: action.payload.file.size,
        attributes: fromJS(action.payload.file.attributes),
        type: action.payload.file.type,
      });
    }
    if (!state.getIn([ action.payload.apiPath, 'items', action.payload.filter ])) 
      state = state.setIn([ action.payload.apiPath, 'items', action.payload.filter ], OrderedMap());
    return state.setIn([ action.payload.apiPath, 'items', action.payload.filter, action.payload.file.name ], file);

  case ADD_AUDIT:
    return state.setIn([ action.payload.apiPath, 'audit', 'more', action.payload.filter ], fromJS(action.payload.audit.more));

  case ADD_AUDIT_ITEM:
    const auditItem = Map({
      created: action.payload.item.created,
      status_code: action.payload.item.status_code,
      reason_phrase: action.payload.item.reason_phrase,
      user: action.payload.item.user,
      action: action.payload.item.action,
      context: action.payload.item.context,
    });
    if (!state.getIn([ action.payload.apiPath, 'audit', 'items', action.payload.filter ]))
      state = state.setIn([ action.payload.apiPath, 'audit', 'items', action.payload.filter ], OrderedMap());
    return state.setIn([ action.payload.apiPath, 'audit', 'items', action.payload.filter,
      action.payload.item.created + '_' + action.payload.item.status_code + '_' + action.payload.item.reason_phrase +
      '_' + action.payload.item.user + '_' + action.payload.item.action + '_' + action.payload.item.context ], auditItem);

  case ADD_ASSIGNMENTS:
    return state.setIn([ action.payload.apiPath, 'assignments', 'more', null ], fromJS(action.payload.assignments.more));

  case ADD_ASSIGNMENT:
    const assignment = Map({
      role: action.payload.assignment.role,
      name: action.payload.assignment.name,
      kind: action.payload.assignment.kind,
    });
    if (!state.getIn([ action.payload.apiPath, 'assignments', 'items', action.payload.filter ]))
      state = state.setIn([ action.payload.apiPath, 'assignments', 'items', action.payload.filter ], OrderedMap());
    return state.setIn([ action.payload.apiPath, 'assignments', 'items', null,
      action.payload.assignment.name + '_' + action.payload.assignment.role + '_' + action.payload.assignment.kind ], assignment);

  case ADD_FILE_CONTENT:
    return state.setIn([ action.payload.path ], fromJS(action.payload.content));

  case CLEAR_STORE:
    return state.clear();

  case APPLY_PATCH:
    try {
      const immpatch = require('immpatch');
      return state.set(action.payload.path, immpatch(state.get(action.payload.path), action.payload.patch));
    }
    catch (e) {
      throw e;
    }

  default:
    return state;
  }
};
