import { LOAD_MORE, CREATE_SPACE, CREATE_PACKAGE, DELETE } from '.';

export function loadMore({ path, cursor = null, filterContext = null, filter = null, fetchAll = false }) {
  return {
    type: LOAD_MORE,
    payload: { filterContext, filter, path, cursor, fetchAll }
  };
}

export function createSpace({ name, policy }) {
  return {
    type: CREATE_SPACE,
    payload: { name, policy }
  };
}

export function createPackage({ spaceName, category, identifier, version, subcategory }) {
  return {
    type: CREATE_PACKAGE,
    payload: { spaceName, category, identifier, version, subcategory }
  };
}

export function _delete({ path }) {
  return {
    type: DELETE,
    payload: { path }
  };
}