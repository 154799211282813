import React from 'react';
import { Row, Col, Input, Button } from 'reactstrap';
import PropTypes from 'prop-types';

import importedIconMenu from '../../assets/img/icons/icon_menu.svg';
import importedIconCamera from '../../assets/img/icons/icon_camera.svg';
import importedIconSearch_grey from '../../assets/img/icons/icon_search_grey.svg';
import importedIconAdd from '../../assets/img/icons/icon_add.svg';

export default function BarSearchMoreAdd({ onClickAdd, onCenterBtnChange, centerIcon=null }) {
  let centerIconSrc = centerIcon;
  if (centerIcon === "menu") {
    centerIconSrc = importedIconMenu;
  }
  else if (centerIcon === "camera") {
    centerIconSrc = importedIconCamera;
  }

  return (
    <Row className="menu-group d-flex mb-10">
      <Col className="element-input d-flex align-items-center flex-fill ">
        <img src={importedIconSearch_grey} alt="action-text" className="img-search"/>
        <Input type="text" className="form-control font-medium" placeholder="Szukaj..."/>
      </Col>
      {!centerIcon ? null :
        <Col>
          {centerIcon === "camera" ?
            <Button className="font-medium btn-with-icon d-flex align-items-center">
              <input id="refBtnTakehoto" type="file" accept="image/*" capture onChange={onCenterBtnChange} className="hideElement"/>
              <label htmlFor="refBtnTakehoto" className="m-0">
                <img src={importedIconCamera} alt="action-text" className="img-btn"/>
              </label>
            </Button>
            : <p className="font-medium btn-with-icon btn-grey d-flex align-items-center">
              <img src={centerIconSrc} alt="action-text" className="img-btn"/>
            </p>
          }
        </Col>
      }
      <Col>
        <Button className="font-medium btn-with-icon d-flex align-items-center" onClick={onClickAdd}>
          <img src={importedIconAdd} alt="action-text" className="img-btn"/>
          <span>Dodaj</span>
        </Button>
      </Col>
    </Row>
  );
}

BarSearchMoreAdd.propTypes = {
  onClickAdd: PropTypes.func,
  onCenterBtnChange: PropTypes.func,
  centerIcon: PropTypes.string,
};