import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container, Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';

import TitleRow from '../components/titleRow';
import EmptyFieldMessageElement from '../components/emptyFieldMessageElement';
import LinkBtn from '../components/linkBtn';
import LinkTextIcon from '../components/linkTextIcon';
import { logOut } from '../../oidc-middleware/actions/login';
import { getProfileRedirect } from '../../main/utils/AuthConfigProvider';
import IBIS_actions from 'ibis-ui/ibis-client-middleware/interface';


@connect(
  state => ({
    projectData: state.IBIS.cache.get('currentProject'),
    projectList: state.IBIS.content.getIn([ '', 'items', null ]),
    IBISContent: state.IBIS.content,
  }),
  dispatch => {
    return bindActionCreators({
      logOut: logOut,
      loadMore: IBIS_actions.loadMore,
      loadFileContent: IBIS_actions.loadFileContent,
    }, dispatch);
  }
)
export default class Profile extends Component {
    state = {
      profileRedirect: null,
      refinedProjectList: [],
    };

    componentDidMount() {
      getProfileRedirect().then(value => this.setState({ profileRedirect: value }));
      this.loadProjectNameList();
    }

    loadProjectNameList = () => {
      this.props.loadMore({ path:'' ,fetchAll: true }).then(() => {
        this.props.projectList && this.props.projectList.forEach((item) => {
          const path = item.get('name') + "/project/" + item.get('name') + "/only/project.json";
          this.props.loadFileContent({ path: path }).then(() => {
            this.setState({ refinedProjectList: this.state.refinedProjectList.concat(
              { name: this.props.IBISContent.getIn([ path,'name' ]),
                projectId: this.props.IBISContent.getIn([ path,'projectId' ]) }) });
          });
        });
      });
    };

    createProjectNameList = () => {
      if (this.state.refinedProjectList.length===0) {
        return (<EmptyFieldMessageElement msg="Brak stworzonych projektów."/>);
      }
      else {
        return this.state.refinedProjectList.map( (item) => {
          return (
            <LinkTextIcon to={'/project/' + item["projectId"]}
              text={item["name"]} key={item["projectId"]}/>
          );
        });
      }
    };

    render() {
      return (
        <Container className="profile">
          <Row className="project-list box-share">
            <Col>
              <TitleRow title="Moje projekty" extraCssClass="mb-0"/>
              {this.createProjectNameList()}
            </Col>
          </Row>
          <LinkBtn
            to='/createnew'
            color="white"
            text="Nowy projekt"
            extraCssClass="mt-20"/>
          <Row className="btn-with-text box-share d-flex align-items-center justify-content-center">
            <p>Moje projekty VOXBOX</p>
          </Row>
          <Row className="btn-with-text box-share d-flex align-items-center justify-content-center">
            <p>Notyfikacje</p>
          </Row>
          {this.state.profileRedirect && <LinkBtn
            to={ this.state.profileRedirect }
            color="white"
            text="Ustawienia konta"
            extraCssClass="mt-20"
            external={true}
          />}
          <LinkBtn to="/"
            color="white"
            text="Wyloguj"
            extraCssClass="mt-20"
            onClickFun={()=>this.props.logOut()}/>
        </Container>
      );
    }
}

Profile.propTypes = {
  projectData: PropTypes.object,
  projectList: PropTypes.object,
  resetProjectData: PropTypes.func,
  logOut: PropTypes.func,
  loadMore: PropTypes.func,
  loadFileContent: PropTypes.func,
  IBISContent: PropTypes.object,
};
