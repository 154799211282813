import * as ibis from './ibis-client-lib';
import { __env } from '../envloader';
import { AccessTokenProvider } from '../main/hoc/utils/access-token-provider';
import { getGV } from '../main/utils/globalVariablesProvider';

function getSecret() {
  return getGV('secret');
}

function isLoggedIn() {
  return getGV('accessToken');
}


let client;

export function createIBISClient(axiosInstance) {
  client = new ibis.Client({
    tenant_url: __env.IBIS_TENANT_URL,
    token_provider: new AccessTokenProvider(),
    axiosInstance: axiosInstance,
    getSecret: getSecret,
    isLoggedIn: isLoggedIn
  });
}

export function loadMore(path, filterContext = null, filter = null, cursor = null, fetchAll = false) {
  let querry = cursor;
  if (!querry)
    querry = { [filterContext]: filter };
  const directory = client.bind(path);
  return directory.fetch(querry, fetchAll).then(directory => {
    return directory;
  }).catch(function (error) {
    throw error;
  });
}

export function loadMoreAudits(path, filter = null, cursor = null, fetchAll = false) {
  let querry = cursor;
  if (!querry)
    querry = { status_code: filter };
  const directory = client.bind(path);
  return directory.audits.fetch(querry, fetchAll).then(audit => {
    return audit;
  }).catch(function (error) {
    throw error;
  });
}

export function loadMoreAssignments(path, cursor = null, fetchAll = false) {
  let querry = cursor;
  if (!querry)
    querry = {};
  const directory = client.bind(path);
  return directory.assignments.fetch(querry, fetchAll).then(assignments => {
    return assignments;
  }).catch(function (error) {
    throw error;
  });
}

export function loadFileContent(path, contentType = 'unknown', responseType=null) {
  const directory = client.bind(path.substring(0, path.lastIndexOf('/') + 1));
  const name = path.substring(path.lastIndexOf('/') + 1);
  const file = directory.bind({
    type: 'file',
    name: name,
    attributes: null,
    content_type: contentType,
    size: null,
    user_content_last_modified: null
  });
  return file.getFileContent(responseType).then(content => {
    return content;
  }).catch(function (error) {
    throw error;
  });
}

export function createSpace(name, policy) {
  const directory = client.bind('');
  const space = directory.bind({
    name: name,
    attributes: {
      policy: policy,
      entity: 'space'
    },
    more: null,
    type: 'directory'
  });
  return space.create().then(() => {
    return space;
  }).catch(function (error) {
    throw error;
  });
}

export function createPackage(spaceName, category, subcategory, identifier, version) {
  const directory = client.bind(spaceName + '/');
  const _package = directory.bind({
    name: category + '/' + identifier + '/' + version,
    attributes: {
      category: category,
      state: '',
      subcategory: subcategory,
      entity: 'package'
    },
    more: null,
    type: 'directory'
  });
  return _package.create().then(() => {
    return _package;
  }).catch(function (error) {
    throw error;
  });
}

export function createFile(path, inFile){
  const directory = client.bind(path.substring(0, path.lastIndexOf('/') + 1));
  const name = path.substring(path.lastIndexOf('/') + 1);
  const file = directory.bind({
    attributes: { entity: 'file' },
    content_type: inFile.type,
    name: name,
    size: inFile.size,
    user_content_last_modified: inFile.lastModifiedDate.toISOString(),
    type: 'file'
  });

  let formData = new FormData();
  formData.append('content', inFile);
  return file.create({ content: formData, 'content_type': 'multipart/form-data' }).then(() => {
    return file;
  }).catch(function (error) {
    throw error;
  });
}

export function getAttributes() {
  return client.entity.fetchAttributes().then(attributes => {
    return attributes;
  }).catch(function (error) {
    throw error;
  });
}

export function exists(path) {
  const directory = client.bind(path);
  return directory.exists();
}

export function _delete(path) {
  const directory = client.bind(path);
  return directory.delete();
}

export function patchIBISfile(path, content) {
  const directory = client.bind(path.substring(0, path.lastIndexOf('/') + 1));
  const name = path.substring(path.lastIndexOf('/') + 1);
  const file = directory.bind({
    attributes: { },
    content: content,
    content_type: 'application/json',
    name: name,
    size: '',
    user_content_last_modified: '',
    type: 'file'
  });

  return file.patch().then(response => {
    return response;
  });
}
