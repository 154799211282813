import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { useSelector } from 'react-redux';
import moment from 'moment';
import 'moment/locale/pl';
import 'react-big-calendar/lib/css/react-big-calendar.css';

moment.locale('pl');
const localizer = momentLocalizer(moment);

const EventCalendar = () => {
  const events = useSelector(state => state.IBIS.cache.getIn([ 'currentProject','renovationWorks' ])).map(event => {
    return {
      start: new Date(event.getIn([ 'date', 'start' ])),
      end: new Date(event.getIn([ 'date', 'end' ])),
      title: event.getIn([ 'value' ]),
    };
  }).toArray();

  const [ date, setDate ] = useState(new Date());

  function showMore(count){
    return '+' + count.toString() + ' Więcej';
  }

  function onNavigate(date){
    setDate(date);
  }

  function onView(view) {
    if (view==='agenda') {
      setDate(new Date());
    }
    if (view === 'month') {
      setDate(new Date());
    }
  }

  return (
    <Row className={"pt-3"}>
      <Col>
        <Calendar
          messages={{
            next: "Następny",
            previous: "Poprzedni",
            today: "Obecny",
            date: "Data",
            time: "Czas",
            event: "Wydarzenie",
            day: "Dzień",
            month: "Miesiąc",
            agenda: "Lista",
            allDay: "Cały dzień",
            noEventsInRange: "Brak wydarzeń",
            showMore: showMore
          }}
          localizer={localizer}
          date={date}
          defaultDate={new Date()}
          defaultView="month"
          views={[ 'day', 'month', 'agenda' ]}
          events={events}
          style={{ height: "80vh" }}
          onNavigate={onNavigate}
          onView={onView}
        />
      </Col>
    </Row>
  );
};

export default EventCalendar;
