import { fromJS, List, Map } from 'immutable';
import { v4 as uuidv4 } from 'uuid';

import { PATCH_JSON } from 'ibis-ui/ibis-client-middleware/interface';

export function addRoomsToNewProject(data) {
  return {
    type: PATCH_JSON,
    payload: {
      name: 'currentProject',
      patch: [ {
        op: 'add',
        path: '/rooms',
        value: fromJS(data.selectedItems)
      }, {
        op: 'add',
        path: '/database',
        value: fromJS(data.database)
      } ]
    }
  };
}

export function addRenovationWorksToNewProject(data) {
  return {
    type: PATCH_JSON,
    payload: {
      name: 'currentProject',
      patch: [ {
        op: 'add',
        path: '/renovationWorks',
        value: fromJS(data.selectedItems)
      },
      {
        op: 'replace',
        path: '/isCompleteProject',
        value: true
      } ]
    }
  };
}

export function addNewCatalogToDatabase(data) {

  return {
    type: PATCH_JSON,
    payload: {
      name: 'currentProject',
      patch: [ {
        op: 'add',
        path: '/database/'+data.get("group")+'/'+
                  ((data.get("group") === "products" && !data.get("isMainCatalog")) ?
                    data.get("catalog")+"/subCatalogs/" : "")+
                  data.get("newCatalog"),
        value: Map({ catalogName: data.get("newCatalog"), files: List() })
      } ]
    } }; 

}

export function addFileToDatabase(data) {
  return {
    type: PATCH_JSON,
    payload: {
      name: 'currentProject',
      patch: [ {
        op: 'add',
        path: '/database/'+data.get("group")+'/'+data.get("catalogName")+'/files/-',
        value: data.get("fileData")
      } ]
    }
  };
}

export function editFileInDatabase(data) {
  return {
    type: PATCH_JSON,
    payload: {
      name: 'currentProject',
      patch: [ {
        op: 'replace',
        path: '/database/'+data.get("group")+'/'+data.get("catalogName")+'/files/'+data.get("fileId"),
        value: data.get("fileData")
      } ]
    }
  };
}

export function deleteFileFromDatabase(data) {
  return {
    type: PATCH_JSON,
    payload: {
      name: 'currentProject',
      patch: [ {
        op: 'remove',
        path: '/database/'+data.get("group")+'/'+data.get("catalogName")+'/files/'+data.get("fileId")
      } ]
    }
  };
}

export function addProductToDatabase(data) {
  return {
    type: PATCH_JSON,
    payload: {
      name: 'currentProject',
      patch: [ {
        op: 'add',
        path: '/database/'+data.get("group")+'/'+data.get("catalog")+"/subCatalogs/"+
                data.get("subCatalog")+'/files/-',
        value: data.get("newProduct")
      } ]
    }
  };
}

export function editProductInDatabase(data) {
  return {
    type: PATCH_JSON,
    payload: {
      name: 'currentProject',
      patch: [ {
        op: 'replace',
        path: '/database/'+data.get("group")+'/'+data.get("catalog")+"/subCatalogs/"+
                data.get("subCatalog")+'/files/'+data.get("fileId"),
        value: data.get("newProduct")
      } ]
    }
  };
}

export function deleteProductFromDatabase(data) {
  return {
    type: PATCH_JSON,
    payload: {
      name: 'currentProject',
      patch: [ {
        op: 'remove',
        path: '/database/'+data.get("group")+'/'+data.get("catalog")+"/subCatalogs/"+
                data.get("subCatalog")+'/files/'+data.get("fileId")
      } ]
    }
  };
}

export function changeWorkName(data) {
  return {
    type: PATCH_JSON,
    payload: {
      name: 'currentProject',
      patch: [ {
        op: 'replace',
        path: '/renovationWorks/'+data.forName+'/value',
        value: data.newValue
      } ]
    }
  };
}

export function changePriceSupposed(data) {
  return {
    type: PATCH_JSON,
    payload: {
      name: 'currentProject',
      patch: [ {
        op: 'replace',
        path: '/renovationWorks/'+data.forName+'/priceSupposed',
        value: data.newValue
      } ]
    }
  };
}

export function changePriceReal(data) {
  return {
    type: PATCH_JSON,
    payload: {
      name: 'currentProject',
      patch: [ {
        op: 'replace',
        path: '/renovationWorks/'+data.forName+'/priceReal',
        value: data.newValue
      } ]
    }
  };
}

export function changeDate(data) {
  return {
    type: PATCH_JSON,
    payload: {
      name: 'currentProject',
      patch: [ {
        op: 'replace',
        path: '/renovationWorks/' + data.forName + '/date/' + data.whichDate,
        value: data.newValue
      } ]
    }
  };
}

export function addNewRenovationWork(data) {
  return {
    type: PATCH_JSON,
    payload: {
      name: 'currentProject',
      patch: [ {
        op: 'add',
        path: '/renovationWorks/'+uuidv4(),
        value: fromJS({
          value: data,
          priceSupposed: 0,
          priceReal: 0 })
      } ]
    }
  };
}

export function createTestProject() {
  return {
    type: PATCH_JSON,
    payload: {
      name: 'currentProject',
      patch: [ {
        op: 'replace',
        path: '',
        value: fromJS(testData)
      } ]
    }
  };
}

const testData = {
  "projectId": "test007",
  "name": "project_testowy",
  "placeState": "radio_house_raw_open",
  "isCompleteProject": true,
  "rooms": [
    {
      name: "kuchnia",
      value: 'Kuchnia',
    },
    {
      name: "biuro",
      value: 'Biuro'
    },
    {
      name: "sypialnia",
      value: 'Sypialnia'
    }
  ],
  "renovationWorks": [
    {
      name: "forging_tiles",
      value: 'Skuwanie płytek',
      priceSupposed: 0,
      priceReal: 0
    },
    {
      name: "paint_walls",
      value: 'Malowanie ścian',
      priceSupposed: 0,
      priceReal: 0
    }
  ],
  "database": {
    "documents": {
      "kuchnia": { catalogName: "Kuchnia", files: [] },
      "biuro": { catalogName: "Biuro", files: [] },
      "sypialnia": { catalogName: "Sypialnia", files: [] },
    },
    "inspiration": {
      "kuchnia": { catalogName: "Kuchnia", files: [] },
      "biuro": { catalogName: "Biuro", files: [] },
      "sypialnia": { catalogName: "Sypialnia", files: [] },
      "sypialnia2": { catalogName: "Sypialnia2", files: [] },
      "sypialnia3": { catalogName: "Sypialnia3", files: [] },
    },
    "products": {
      "materialy_budowlane": { catalogName: "Materiały budowlane",
        subCatalogs: {
          "plytki": { catalogName: "Płytki", files: [] },
          "narzedzia": { catalogName: "Narzędzia", files: [] },
          "farby": { catalogName: "Farby", files: [] },
          "gladz": { catalogName: "Gładź", files: [] }
        }
      },
      "kuchnia": {
        catalogName: "Kuchnia",
        subCatalogs: {
          "plytki": { catalogName: "Płytki", files: [] },
          "szafki": { catalogName: "Szafki", files: [] },
          "sztucce": { catalogName: "Sztućce", files: [] },
        }
      },
      "biuro": {
        catalogName: "Biuro",
        subCatalogs: {
          "panele": { catalogName: "Panele", files: [] },
          "oswietlenie": { catalogName: "Oświetlenie", files: [] },
          "sprzet": { catalogName: "Sprzęt", files: [] }
        }
      },
    }
  }
};