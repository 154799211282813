import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Route, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { bindActionCreators } from 'redux';

import TitleRow from '../components/titleRow';
import BarSearchMoreAdd from '../components/barSearchMoreAdd';
import { renderCatalogs } from '../utils/projectFunc';

import { addNewCatalogToDatabase } from '../actions/projectActions';
import ModalAddNewCatalog from '../components/modalNewCatalog';
import ProductsBoard from './productsBoard';
import EmptyFieldMessageElement from '../components/emptyFieldMessageElement';

@withTranslation()
@withRouter
@connect(
  state => ({
    projectData: state.IBIS.cache.get('currentProject')
  }),
  dispatch => {
    return bindActionCreators({  addNewCatalogToDatabase }, dispatch);
  }
)
export default class Products extends Component {
  state = {
    isShowModalAddNewCatalog: false,
    catalogLevel: -1,
    catalog: null,
    subCatalog: null
  };

  static getDerivedStateFromProps(props, state) {
    if (!(props.location.pathname).endsWith("/products")) {
      let currUrl = props.location.pathname;
      const regexUrlTwoCatalogLevel = RegExp('/products/((?!/).)+/((?!/).)+');
      if (regexUrlTwoCatalogLevel.exec(currUrl) === null) {
        const regexUrlCatalog = RegExp(/\/products\/((?!\/).)+/g);
        return {
          catalogLevel: 1,
          catalog: (currUrl.match(regexUrlCatalog))[0].split("/products/")[1]
        };
      }
      else {
        return {
          catalogLevel: 2,
          subCatalog: (currUrl.match(regexUrlTwoCatalogLevel))[0].split("/products/"+state.catalog+"/")[1]
        };
      }
    }
    else {
      return {
        catalogLevel: 0
      };
    }
  }

  modalAddNewShowHide = () => {
    this.setState((prevState) => ({
      isShowModalAddNewCatalog: !prevState.isShowModalAddNewCatalog
    }));
  };

  addNewCatalog = (newName) => {
    this.props.addNewCatalogToDatabase(Map({
      projectId: this.props.projectData.get("projectId"),
      group: "products",
      newCatalog: newName,
      catalog: this.state.catalog,
      isMainCatalog: (this.state.catalogLevel === 0)
    }));
    this.modalAddNewShowHide();
  };

  renderCatalogElement = (catalogs, nextUrl) => {
    if (this.state.catalogLevel > 0 && catalogs.size === 0) {
      return (<EmptyFieldMessageElement msg={this.props.t('project.db_empty_catalog')}/>);
    }
    else {
      return renderCatalogs(catalogs,true, nextUrl, "mb-4");
    }
  };

  renderContent = () => {
    const { t } = this.props;
    let catalogs, nextUrl, title;
    if (this.state.catalogLevel === 0) {
      catalogs = this.props.projectData.getIn([ "database", "products" ]);
      nextUrl = this.props.match.url;
      title = t('project.products');
    }
    else {
      let subCatalogs = this.props.location.pathname.split("/products/")[1].split("/");

      let catalogData = this.props.projectData.getIn([ "database", "products", subCatalogs[0] ]);
      catalogs = catalogData.get("subCatalogs");
      nextUrl = this.props.location.pathname;
      title = catalogData.get("catalogName");
    }

    return (
      <Row className="products">
        <Col>
          <TitleRow title={title} extraCssClass="main-title"/>
          <BarSearchMoreAdd onClickAdd={() => this.modalAddNewShowHide()}/>
          {catalogs.size > 0 ?
            <Row className="my-3">
              <p>{t('form.catalogs')}</p>
            </Row>
            : null}
          <Row className="d-flex flex-wrap align-items-start justify-content-start catalogs-container">
            {this.renderCatalogElement(catalogs, nextUrl)}
          </Row>
        </Col>
        {this.state.isShowModalAddNewCatalog ?
          <ModalAddNewCatalog
            onCancel={() => this.modalAddNewShowHide()}
            onSave={(newName)=>this.addNewCatalog(newName)}/>
          : null
        }
      </Row>
    );
  };

  render() {
    //TODO: moze inny sposob na to?
    if (this.state.catalogLevel < 2) {
      return this.renderContent();
    }
    else {
      return (
        <Route path={`${this.props.match.path}/:catalog/:subCatalog`} component={ProductsBoard}/>
      );
    }
  }
}

Products.propTypes = {
  addNewCatalogToDatabase: PropTypes.func,
  match: PropTypes.object,
  location: PropTypes.object,
  projectData: PropTypes.object,
  t: PropTypes.func
};
