import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

export default function LinkBtn({ color, extraCssClass, onClickFun, text, to, external }) {
  let cssClassName = "d-flex align-items-center justify-content-center font-medium box-share btn-with-text cursor ";
  if (color === "red") {
    cssClassName += "btn-red";
  }
  else {
    cssClassName += "btn-white";
  }

  if (extraCssClass !== undefined) {
    cssClassName += " "+ extraCssClass;
  }

  if (external) {
    return (
      <a className={cssClassName} href={to} onClick={onClickFun}>
        {text}
      </a>
    );
  }

  return (
    <Link className={cssClassName} to={to} onClick={onClickFun}>
      {text}
    </Link>
  );
}

LinkBtn.propTypes = {
  extraCssClass: PropTypes.string,
  to: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]),
  text: PropTypes.string,
  color: PropTypes.string,
  onClickFun: PropTypes.func,
  external: PropTypes.bool,
};