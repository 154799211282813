import React from 'react';
import { Row } from 'reactstrap';
import PropTypes from 'prop-types';

export default function BudgetGeneralCostBar(props) {
  let sumPriceSupposed = 0, sumPriceReal = 0, costAnimateColor="black", costBarFilledWidth=0;
  if (props.list !== undefined && props.list.size > 0) {
    props.list.forEach(item => {
      sumPriceSupposed += item.get('priceSupposed');
      sumPriceReal += item.get('priceReal');
    });
    if (sumPriceReal > sumPriceSupposed) {
      costAnimateColor = "#E80808";
      costBarFilledWidth = 100;
    }
    else {
      costAnimateColor = "black";
      costBarFilledWidth = (sumPriceReal / sumPriceSupposed) * 100;
    }
  }
  return (
    <React.Fragment>
      <Row className={"d-flex justify-content-between title-group "+(props.extraCssClass?props.extraCssClass:"")}>
        <p className="font-medium">{props.t('budget.general_cost')}</p>
        <p className="font-medium">
          <span>{sumPriceSupposed}</span>
          <span> / </span>
          <span style={{ color: costAnimateColor }}>{sumPriceReal} zł</span>
        </p>
      </Row>
      <Row id="costBarContainer" className="cost-bar box-share">
        <p style={{
          "width": (Number.isNaN(costBarFilledWidth) ? 0 : costBarFilledWidth) +"%",
          "backgroundColor": costAnimateColor
        }}/>
      </Row>
    </React.Fragment>
  );
}

BudgetGeneralCostBar.propTypes = {
  extraCssClass: PropTypes.string,
  list: PropTypes.object,
  t: PropTypes.func
};