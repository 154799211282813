import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import PropTypes from 'prop-types';


export default function RadioVox(props) {
  return (
    <FormGroup check className="radio-container-vox indentation-in-select-elems">
      <Label check>
        <Input type="radio" name={props.name} value={props.value} checked={props.selectedOption === props.value}
          onChange={props.onChange}/>
        <p className="radio-icon-vox"></p>
        <p className="text-radio-or-check">{props.text}</p>
      </Label>
    </FormGroup>
  );
}

RadioVox.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  selectedOption: PropTypes.string,
  text: PropTypes.string,
  onChange: PropTypes.func,
};